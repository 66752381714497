<template>
  <v-card-text>
    <div class="inner-grid pt-10">
      <CardBlock style="grid-column: span 4">
        <v-container>
          <v-row>
            <v-col cols="4" class="d-flex flex-column">
              <v-sheet
                class="d-flex flex-column"
                color="transparent"
                style="grid-column: span 2"
              >
                <v-avatar
                  class="elevation-2"
                  color="grey-darken-1"
                  size="180"
                  cover
                >
                  <lazy-image
                    v-if="entity.featuredImage"
                    :image="entity.featuredImage"
                    cover
                  />
                  <v-icon v-else :icon="mdiWarehouse" size="128" />
                </v-avatar>
                <h3 class="text-h5">{{ entity.title }}</h3>
                <h4 class="text-subtitle-1" style="">
                  {{ entity.register_id }}
                </h4>
              </v-sheet>
              <SocialMedia
                noWidthOnCount
                elementSpacing="mr-2"
                alignment="start"
                :list="
                  view.social_media.filter((item) =>
                    item.entities.includes(entity.id),
                  )
                "
              >
              </SocialMedia>
            </v-col>
            <v-col cols="8">
              <sourced-value-table
                :items="[
                  ...upperTable,
                  ...informationTable,
                  ['Gegenstand', [{ value: entity.purpose }]],
                ]"
              />
            </v-col>
          </v-row>
        </v-container>
      </CardBlock>

      <v-card
        style="grid-column: span 4"
        v-if="filteredSocials.length"
        :title="`${entity.title}
        in
        sozialen Medien
        `"
      >
        <social-media-chart :model-value="filteredSocials" />
      </v-card>
      <div style="grid-column: span 4">
        <NewsBlock for-show :list="pressData" :with-drawer="false" />
      </div>
      <v-sheet
        color="transparent"
        style="grid-column: span 4"
        v-if="imageList.length > 0"
        ><CardBlock title="Fotos">
          <GalleryBlock :imageList="imageList" /> </CardBlock
      ></v-sheet>
    </div>
  </v-card-text>
</template>

<script setup lang="ts">
import {
  DossierView,
  EntityDataInfo,
  SourcedValue,
} from "@/common/store/dossier"
import LazyImage from "@/common/components/LazyImage.vue"
import CardBlock from "@/common/components/view/CardBlock.vue"
import GalleryBlock from "@/common/components/view/GalleryBlock.vue"
import SourcedValueTable from "@/common/components/view/SourcedValueTable.vue"
import { computed } from "vue"
import { mdiWarehouse } from "@mdi/js"
import SocialMedia from "@/common/components/view/SocialMedia.vue"
import SocialMediaChart from "@/common/components/view/SocialMediaChart.vue"
import DataValueDisplay from "@/common/components/view/DataValueDisplay.vue"
import NewsBlock from "./NewsBlock.vue"

const props = defineProps<{
  view: DossierView
  entity: EntityDataInfo & { type: "company" }
}>()

const upperTable = computed(() => {
  const retval = []
  if (props.entity.foundingDate) {
    retval.push(["Gegründet", [props.entity.foundingDate]])
  }
  for (const addr of props.entity.addresses || []) {
    retval.push([
      addr.value.label,
      [{ value: addr.value.address, source: addr.source }],
    ])
  }

  return retval as [string, SourcedValue<any>[]][]
})

const informationTable = computed(() => {
  return [...Object.entries(props.entity.otherInformation).sort()] as [
    string,
    SourcedValue<any>[],
  ][]
})

const pressData = computed(() => {
  return props.view.web_snapshots.filter(
    (x) => x.entities?.includes(props.entity.id),
  )
})

const filteredSocials = computed(() =>
  props.view.social_media.filter(
    (item) =>
      item.entities.includes(props.entity.id) &&
      parseInt(`${item?.followers}` || "0") > 0,
  ),
)

const imageList = computed(() => {
  return props.view.images.filter(
    (item) =>
      !item.globalFeatured &&
      !item.featured &&
      item.entities.includes(props.entity.id),
  )
})
</script>

<style scoped lang="scss">
.inner-grid {
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ". .";
}
</style>
