<template>
  <graph-display
    :nodes="nodes"
    :edges="edges"
    :preLayouts="preLayouts"
    @show-entity="$emit('showEntity', $event)"
    @update:networkPositionsOut="$emit('update:networkPositionsOut', $event)"
  />
</template>

<script lang="ts" setup>
import "v-network-graph/lib/style.css"
import { computed, Ref, watchEffect } from "vue"
import {
  DossierView,
  EntityDataEntityIdentifier,
  EntityDataInfo,
  IconType,
  ImageInfo,
  SocialMediaTypeX,
} from "@/common/store/dossier"
import colors from "vuetify/util/colors"
import { useGraph } from "@/common/lib/graph"
import { asyncComputed } from "@vueuse/core"
import GraphDisplay from "@/common/components/view/GraphDisplay.vue"
import { mdiAccount, mdiWarehouse } from "@mdi/js"
import { Layouts } from "v-network-graph"
const props = withDefaults(
  defineProps<{
    view: DossierView
    showSocial?: boolean
    preLayouts?: Layouts
  }>(),
  {
    showSocial: false,
  },
)
defineEmits(["showEntity", "update:networkPositionsOut"])

const { nodes, edges, addEdge, addNode } = useGraph<
  EntityDataEntityIdentifier | `_siteGroup/${string}` | `_site/${string}`,
  {
    name: string
    initial?: string
    icon: string | IconType
    color: string
    image?: ImageInfo
    url?: string
    isParent?: boolean
    imageUrl?: Ref<string | null> | null
  },
  {
    relationship?: string
  }
>()

const socialByEntity = computed(() => {
  const retval = {} as Record<
    EntityDataEntityIdentifier,
    (SocialMediaTypeX & { nodeId: `_site/${string}` })[]
  >
  for (const [i, social] of props.view.social_media.entries()) {
    for (const entityId of social.entities) {
      ;(retval[entityId] ??= []).push({
        ...social,
        nodeId: `_site/${i}`,
      })
    }
  }
  return retval
})

watchEffect(() => {
  for (const [entityId, entity] of Object.entries(props.view.entities) as Array<
    [EntityDataEntityIdentifier, EntityDataInfo]
  >) {
    addNode(entityId, {
      name: entity.title,
      icon: entity.type === "person" ? mdiAccount : mdiWarehouse,
      color: entity.type === "person" ? colors.blue.base : colors.cyan.base,
      initial: entity.initial,
      isParent: true,
      image: entity.featuredImage,
      imageUrl: entity.featuredImage
        ? asyncComputed(
            async () => await entity.featuredImage!.lazyUrl(),
            entity.featuredImage.thumbnailUrl,
          )
        : null,
    })

    if (entity.type === "person") {
      for (const person_b of entity.relatedPersons) {
        addEdge(entityId, person_b)
      }
      for (const [company_b, role] of Object.entries(
        entity.relatedCompanies,
      ) as Array<[EntityDataEntityIdentifier, string]>) {
        addEdge(entityId, company_b, { relationship: role })
      }
    } else if (entity.type === "company") {
      for (const company_b of entity.relatedCompanies) {
        addEdge(entityId, company_b)
      }
    }

    if (props.showSocial) {
      for (const social of socialByEntity.value[entityId] ?? []) {
        addNode(social.nodeId, {
          isParent: false,
          ...social,
        })
        addEdge(entityId, social.nodeId)
      }
    }
  }
})
</script>

<style lang="scss"></style>
