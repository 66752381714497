<script lang="ts" setup>
import {
  EntityDataEntityIdentifier,
  EntityDataInfo,
  WebSnapshotInfo,
} from "@/common/store/dossier/types"
import { UseObjectUrl } from "@vueuse/components"
import { Ref, computed, ref } from "vue"
import { vOnClickOutside } from "@vueuse/components"
import type { OnClickOutsideHandler } from "@vueuse/core"
import NewsItem from "./NewsItem.vue"
import LazyFile from "@/common/components/LazyFile.vue"
import ShortDate from "@/common/components/ShortDate.vue"
import {
  mdiFileDocument,
  mdiNewspaper,
  mdiAccount,
  mdiImage,
  mdiCalendar,
  mdiWarehouse,
  mdiAccountGroup,
} from "@mdi/js"

const props = withDefaults(
  defineProps<{
    list: WebSnapshotInfo[]
    withDrawer?: boolean
    forShow?: boolean
    maxHeight?: string
    entities?: {
      [id: EntityDataEntityIdentifier]: EntityDataInfo
    }
  }>(),
  { withDrawer: true, forShow: false, maxHeight: "720px" },
)
const emit = defineEmits(["set-app-drawer"])

const clearSelected = () => {
  emit("set-app-drawer", false)
  showDrawer.value = false
  selectedIndex.value = null
}
function setNews(i: number | null) {
  emit("set-app-drawer", false)
  if (selectedIndex.value === i) {
    clearSelected()
  } else {
    selectedIndex.value = i
    showDrawer.value = true
  }
}

const drawerHandler: OnClickOutsideHandler = () => {
  if (showDrawer.value) showDrawer.value = false
}

const selectedIndex = ref(null as number | null)

const showDrawer = ref(false)
const selectedItem = computed(() =>
  selectedIndex.value !== null ? filteredData.value[selectedIndex.value] : null,
)
const pinnedData = computed(() => {
  return props.list.slice().sort((a, b) => {
    if (b.important !== a.important) {
      return a.important ? -1 : 0
    }
    return 0
  })
})

const filteredData = computed(() => {
  const type = currentTabType.value
  if (currentTabType.value === "all") {
    return pinnedData.value
  } else {
    return pinnedData.value.filter((x) => x.type === type)
  }
})

const currentTabType: Ref<string | null> = ref(null)
const pressTypeIcons: any = {
  press: mdiNewspaper,
  company_db: mdiWarehouse,
  person_db: mdiAccount,
  image: mdiImage,
  document: mdiFileDocument,
  post: mdiAccountGroup,
  website: mdiCalendar,
  other: mdiCalendar,
}
const tabList = computed(() => {
  const summary: any = {}
  pinnedData.value.forEach((item: any) => {
    if (!summary[item.type]) {
      summary[item.type] = { count: 0, icon: pressTypeIcons[item.type] }
    }
    summary[item.type].count++
  })
  const TabsOrder = [
    "press",
    "post",
    "company_db",
    "person_db",
    "image",
    "document",
    "website",
    "other",
  ]
  const reorderedSummary: any = {}
  TabsOrder.forEach((tab) => {
    if (summary[tab]) {
      reorderedSummary[tab] = summary[tab]
    }
  })
  return reorderedSummary
})
</script>

<template>
  <div class="news-list" :style="`max-height:${maxHeight}`">
    <v-tabs
      v-model="currentTabType"
      align-tabs="start"
      @update:modelValue="clearSelected"
      height="65"
    >
      <v-tab
        v-for="(eType, key) in tabList"
        size="small"
        :value="key"
        :key="key"
        ><v-icon :icon="eType.icon" class="mr-2"></v-icon
        >{{ $t("pressType." + key) }} ({{ eType.count }})</v-tab
      >
    </v-tabs>
    <NewsItem
      :entities="entities"
      :hover="!forShow"
      :link="!forShow"
      :item="entry"
      v-for="(entry, i) of filteredData"
      :key="i"
      @click="withDrawer ? setNews(i) : undefined"
      :class="{ selected: selectedIndex === i, 'cursor-pointer': !forShow }"
    />
  </div>

  <v-navigation-drawer
    v-model="showDrawer"
    temporary
    width="650"
    location="right"
    :scrim="false"
    v-if="withDrawer"
    class="news-drawer"
  >
    <Suspense fallback="Loading..." v-if="selectedItem && selectedItem.content">
      <lazy-file
        :model-value="selectedItem"
        :key="selectedItem.content.sourceBlock?.id"
      >
        <template v-slot="{ file }">
          <UseObjectUrl v-slot="url" :object="file">
            <div class="news-item" v-on-click-outside="drawerHandler">
              <v-toolbar>
                <v-toolbar-title
                  class="text-subtitle-2 font-weight-bold"
                  :model-value="selectedItem"
                  >Archivierte Website vom
                  <short-date
                    v-if="selectedItem.date"
                    :date="new Date(file.lastModified)"
                  ></short-date
                ></v-toolbar-title>
                <v-btn
                  target="_blank"
                  :href="selectedItem.source"
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  URL öffnen
                </v-btn>
              </v-toolbar>
              <iframe :src="url.value" class="can-interact"> </iframe>
            </div>
          </UseObjectUrl>
        </template>
      </lazy-file>
    </Suspense>
  </v-navigation-drawer>
</template>
<style lang="scss">
.news-list {
  width: 100%;
  height: 100%;
  overflow: auto;

  .selected {
    outline: 5px solid rgb(var(--v-theme-primary));
  }
}
.news-drawer {
  iframe {
    transform-origin: 0 0;
    transform: scale(0.5);
    height: 200%;
    width: 200%;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: -1;
    &.can-interact {
      z-index: 1;
    }
  }
}

// .news-carousel {
//   .v-window__controls {
//     .v-btn {
//       background-color: transparent !important;
//       border: 1px solid rgb(var(--v-theme-primary));
//       color: rgb(var(--v-theme-primary)) !important ;
//     }
//   }
// }
</style>
