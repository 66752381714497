import {
  LabeledFileSectionTypes,
  SmartChapterTypes,
} from "../store/dossier/types"

import { UserType, UserAction } from '../store/user'

export const SMART_BLOCK_SUBTITLES: Partial<
  Record<SmartChapterTypes | LabeledFileSectionTypes, string>
> = {
  FeaturedPhoto:
    "Dieses Foto wird prominent oben links im Dossier mit Bildbeschreibung angezeigt. Das Seitenverhältnis ist immer 1:1, sprich quadratisch.",
  Chronology:
    "Alle Ereignisse tauchen ENTWEDER in der vertikalen Timeline (Checkbox gesetzt) ODER in der Ereignisliste (Checkbox leer) auf. Domainnamen werden als Überschriften gesetzt",
  KpiBarChart:
    "Bewertung des dossiers nach bestimmten Kriterien auf einer Skala von null bis drei. Im FoH erscheint ein Balkendiagramm mit den Skalenwerten als Beschriftung.",
  SocialMedia:
    'Alle Einträge werden nach Domain sortiert und bekommen ein Icon. Das Netzwerk mit den meisten Treffern wird oben in der Liste angezeigt. Die Spalte "Anzahl Follower" dient zum Größenvergleich der unterschiedlichen SocialMedia Accounts.',
  CompanyInformation:
    "Alle Einträge werden nach Domain sortiert und bekommen ein Icon. Einträge mit Datum werden in der horizontalen Timeline angezeigt, Einträge ohne Datum landen in einer Tabelle. Dabei wird die Domain mit den meisten Treffern oben in der Liste angezeigt.",
  SavedWebsite:
    'Hier als HTML gespeicherte Webseiten hochladen. Wenn Sie mit "Save Page WE-Extension" für Firefox gespeichert wurden, werden alle Metadaten automatisch ausgefüllt.',
  EntityData:
    "Die Informationen dieses Blockes werden überall verwendet wo diese Person/dieses Unternehmen referenziert wird.",
  Archive:
    '"Hier als HTML gespeicherte Webseiten oder Photos via DRAG&DROP hochladen. Für mit "Save Page WE-Extension" für Firefox gespeicherte Webseiten werden alle Metadaten automatisch ausgefüllt"',
  NetworkAnalysis:
    "Sobald unten Personen und Unternehmen eingetragen sind, können Sie hier die Verbindung zwischen Ihnen darstellen.",
}

export const ENTITY_DATA_KEYS = {
  company: ["Gründungsdatum", "Geschäftssitz", "Gegenstand", "Handelsregister"],
  person: [
    "Vor-/Nachname",
    "Aliasnamen",
    "Familienstand",
    "Geburtsdatum",
    "Geburtsort",
    "Adresse",
    "E-Mail",
    "Telefon",
  ],
}

export const USER_ROLES: Record<string, UserType> = {
  SUPERUSER: 'superuser',
  PROFILER: 'profiler',
  ANALYST: 'analyst',
};

export const PERMISSIONS: Record<string, UserAction> = {
  SHOW_DOSSIER: 'show_dossier',
  CREATE_DOSSIER: 'create_dossier',
  DELETE_DOSSIER: 'delete_dossier',
  EDIT_DOSSIER: 'edit_dossier',
  UPDATE_PROFILER: 'update_profiler',
  UPDATE_ANALYST: 'update_analyst',
  UPDATE_LEVEL: 'update_level',
  CREATE_LINK: 'create_link',
};

export const ROLE_PERMISSIONS: Record<UserType, UserAction[]> = {
  superuser: [
    ...Object.values(PERMISSIONS)
  ],
  profiler: [PERMISSIONS.SHOW_DOSSIER,PERMISSIONS.CREATE_DOSSIER,PERMISSIONS.EDIT_DOSSIER,PERMISSIONS.UPDATE_ANALYST,PERMISSIONS.CREATE_LINK,PERMISSIONS.UPDATE_ANALYST],
  analyst: [PERMISSIONS.SHOW_DOSSIER],
}
