<template>
  <apexchart
    height="100%"
    :series="series"
    type="bar"
    :options="{ ...options, ...localOptions }"
  ></apexchart>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { useTheme } from "vuetify/lib/framework.mjs"
const theme = useTheme()
const colors = theme.current.value.colors
import { EventType } from "@/common/store/dossier"

import { useI18n } from "vue-i18n"
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    modelValue: Record<number, Record<string, number>>
    options?: Record<string, any>
  }>(),
  { options: {} as any },
)

const CATEGORIES: Record<
  Exclude<
    EventType,
    "website" | "company_db" | "person_db" | "post" | "social"
  >,
  { name: string; color: string }
> = {
  socialMedia: {
    name: t("eventsType.socialMedia"),
    color: colors.accent2,
  },
  press: {
    name: t("eventsType.press"),
    color: colors.accent1,
  },
  image: {
    name: t("eventsType.image"),
    color: colors.accent5,
  },
  document: {
    name: t("eventsType.document"),
    color: colors.accent3,
  },
  other: {
    name: t("eventsType.ereignisse"),
    color: colors.accent4,
  },
}
const years = computed(() => {
  return Object.keys(props.modelValue).sort()
})

const localOptions = computed(() => ({
  chart: {
    type: "bar",
    stacked: true,
  },

  xaxis: {
    categories: years.value,
  },
  animations: {
    enabled: true,
    easing: "easeinout",
    speed: 800,
    animateGradually: {
      enabled: true,
      delay: 1500,
    },
    dynamicAnimation: {
      enabled: true,
      speed: 350,
    },
  },
}))

const series = computed(() => {
  return Object.keys(CATEGORIES)
    .map((category) => ({
      name: CATEGORIES[category as keyof typeof CATEGORIES].name,
      color: CATEGORIES[category as keyof typeof CATEGORIES].color,
      data: years.value.map(
        (year) => (props.modelValue[parseInt(year)] || {})[category] || 0,
      ),
    }))
    .filter((value) => value.data.some((d) => !!d))
})
</script>
