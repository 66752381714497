import config from "@/config"
import { ClientLogin, ClientRegistration } from "@dossier-direct/crypto"
import { b64encode } from "@/common/lib/encoding"
import type { LoginResult, RootOfTrust } from "./types"
import { RegistrationResult } from "./types"

export class CryptoError extends Error {}
export class LoginStateError extends CryptoError {}
export class RegistrationStateError extends CryptoError {}
export class SecurityViolationError extends CryptoError {}

interface RegistrationStep2Output {
  ke3: number[]
  server_pubkey: number[]
  export_key: number[]
}

interface LoginStep2Output extends RegistrationStep2Output {
  session_key: number[]
}

export function getRootOfTrust(rot?: RootOfTrust) {
  rot = rot || config.rootOfTrust
  if (!rot) {
    console.warn(
      "WARNING WARNING WARNING WARNING\nNo root of trust configured\nOperating without trust",
    )
  }
  return rot
}

export class CryptoHelper {
  private readonly rot?: RootOfTrust

  private loginState?: ClientLogin
  private registrationState?: ClientRegistration

  public constructor(rot?: RootOfTrust) {
    this.rot = getRootOfTrust(rot)
  }

  public cleanup() {
    if (this.loginState) {
      this.loginState.free()
      this.loginState = undefined
    }
    if (this.registrationState) {
      this.registrationState.free()
      this.registrationState = undefined
    }
  }

  public verifyServerPubkey({ server_pubkey }: { server_pubkey: number[] }) {
    if (this.rot?.opaque_server_pubkey) {
      if (
        b64encode(new Uint8Array(server_pubkey)) !=
        this.rot.opaque_server_pubkey
      ) {
        throw new SecurityViolationError()
      }
    }
  }
  public loginStep1(password: Uint8Array): Uint8Array {
    if (this.loginState) {
      this.cleanup()
    }
    this.loginState = new ClientLogin()
    return this.loginState.step1(password)
  }

  public loginStep2(ke2: Uint8Array): LoginResult {
    if (!this.loginState) {
      throw new LoginStateError()
    }
    const step2Results: LoginStep2Output = this.loginState.step2(ke2)
    if (!step2Results) {
      throw new SecurityViolationError()
    }
    this.verifyServerPubkey(step2Results)
    return {
      serverPubkey: new Uint8Array(step2Results.server_pubkey),
      exportKey: new Uint8Array(step2Results.export_key),
      sessionKey: new Uint8Array(step2Results.session_key),
      ke3: new Uint8Array(step2Results.ke3),
    }
  }

  public registrationStep1(password: Uint8Array) {
    if (this.registrationState) {
      this.cleanup()
    }
    this.registrationState = new ClientRegistration()
    return this.registrationState.step1(password)
  }

  public registrationStep2(ke2: Uint8Array): RegistrationResult {
    if (!this.registrationState) {
      throw new RegistrationStateError()
    }
    const step2Results: RegistrationStep2Output =
      this.registrationState.step2(ke2)
    if (!step2Results) {
      throw new SecurityViolationError()
    }
    this.verifyServerPubkey(step2Results)
    return {
      serverPubkey: new Uint8Array(step2Results.server_pubkey),
      exportKey: new Uint8Array(step2Results.export_key),
      ke3: new Uint8Array(step2Results.ke3),
    }
  }
}
