<script lang="ts" setup>
import {
  EntityDataEntityIdentifier,
  EntityDataInfo,
  WebSnapshotInfo,
} from "@/common/store/dossier/types"
import { mdiCalendar, mdiNewspaper, mdiWarehouse, mdiPin } from "@mdi/js"
import ShortDate from "@/common/components/ShortDate.vue"
import { computed } from "vue"
import EntityStackImages from "./EntityStackImages.vue"

const props = withDefaults(
  defineProps<{
    item: WebSnapshotInfo
    entities?: {
      [id: EntityDataEntityIdentifier]: EntityDataInfo
    }
  }>(),
  {},
)
const itemIcon = computed(() => {
  if (props.item.type === "company_db") return mdiWarehouse
  if (props.item.type === "press") return mdiNewspaper
  return mdiCalendar
})
</script>

<template>
  <v-card
    elevation="0"
    class="news mb-3"
    :title="item.label"
    :class="{ important: item.important }"
  >
    <template #subtitle
      >{{ item.title ? item.title + " vom " : ""
      }}<short-date :date="new Date(item.date)" v-if="item.date"
    /></template>
    <template v-slot:prepend>
      <EntityStackImages
        :used-entities="item.entities!"
        :entities="entities!"
        :icon="itemIcon"
      />
    </template>
    <v-icon
      color="grey-lighten-1"
      class="icon-pin"
      v-if="item.important"
      :icon="mdiPin"
    ></v-icon>
  </v-card>
</template>

<style lang="scss">
.news {
  &.important {
    background-color: #ededed;
    .v-card-item__content {
      border: none;
    }
    .icon-pin {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      right: 15px;
      width: 20px;
      height: 20px;
    }
  }
  .v-card-item__content {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 8px;
  }
  .v-card-title {
    font-size: 15px;
  }
}
</style>
