export default {
  home: "Startseite",
  message: {
    loggingIn: "Login-Vorgang",
    loginProcess: {
      securing: "Stelle sichere Serververbindung her ...",
      completing: "Serververbindung gesichert",
      registering: "Benutzerregistrierung ...",
      authenticating: "Benutzer authentifizieren ...",
      registeringFocusWait: "Benutzerregistrierung ...",
      authenticatingFocusWait: "Benutzer authentifizieren ...",
      completed: "Login-Vorgang abgeschlossen",
      unauthenticated: "Login-Vorgang fehlgeschlagen",
      cryptoError: "Sicherheitsfehler aufgetreten",
      otherError: "Verbindungsfehler",
      failedError: "Zugang verweigert",
      wrongPinError: "Falsche PIN",
      noauthError: "Keine Login-Daten",
      webauthnError: "Authentifizierung",
      activatePin: "PIN festlegen",
      requirePin: "PIN erforderlich",
      checkingPin: "Prüfe PIN ...",
      rateLimitError: "Zu viele Versuche",
    },
    exceed_file_size:
      "Datei @:{file} ist größer als 255MiB und kann nicht hochgeladen werden.",
    empty_fields_not_allowed: "Feld darf nicht leer sein.",
    confirm_delete_title: "Dossier löschen",
    confirm_delete_description:
      "Möchten Sie das dossier {id} vom {date} {title} wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
  },
  errorHelp: {
    cryptoError:
      "Es konnte keine geschützte Verbindung zur Datenbank hergestellt werden. " +
      "Aus Sicherheitsgründen wurde der Verbindungsversuch komplett abgebrochen. " +
      "Eventuell sind Ihre Anmeldedaten oder Ihr geheimer Link inkorrekt (zum Beispiel falsch aus einer E-Mail kopiert). " +
      "Bitte wenden Sie Sich an ihre Kontaktperson.",
    otherError:
      "Wir konnten Sie nicht mit dem dossier verbinden. Ursachen können eine gestörte Internetverbindung, ein " +
      "Serverfehler oder ein abgelaufener Link sein. Bitte schließen Sie Netzwerkprobleme aus und versuchen es dann noch einmal.",
    webauthnError:
      "Die Authentifizierung mit Ihrem zweiten Faktor ist fehlgeschlagen. Bitte nutzen Sie dasselbe Gerät und " +
      "denselben Browser wie bei der Registrierung. Laden Sie diese Seite neu, um es noch einmal zu probieren.",
    failedError: "Bitte neu laden um es erneut zu versuchen.",
    wrongPinError:
      "Sie haben eine falsche PIN eingegeben. Versuchen Sie es noch einmal oder bitten Sie Ihre Kontaktperson Ihren Zugang zurückzusetzen.",
    noauthError: "Wenden Sie sich an den Architekten.",
    rateLimitError:
      "Zu viele falsche Versuche. Bitte warten Sie einige Minuten, bis Sie es noch einmal versuchen.",
  },
  action: {
    reload: "Neu laden",
    retry: "Neuer Versuch",
    pinEntry: "Zur PIN-Eingabe",
    agree: "zustimmen",
    cancel: "Abbrechen",
    delete: "LÖSCHEN"
  },
  pinProcess: {
    setPin: "PIN festlegen",
    copyPin: "PIN kopieren",
    inputPin: "PIN eingeben",
    continue: "Weiter",
    haveSaved: "Weiter zum dossier",
    pinHint: "PIN vergessen? Wenden Sie sich an Ihre Kontaktperson.",

    primed: {
      title: "Fehlgeschlagene Authentifizierung",
      text:
        "Sie haben sich erfolgreich mit ihrem dossier verbunden, aber wir konnten Sie nicht auf Anhieb identifizieren. " +
        "Ein extra Fenster zur Passkey / Hauptschlüssel - Eingabe sollte sich geöffnet haben. Versuchen Sie es noch " +
        "einmal indem sie diese Seite neuladen. Sollte dies nicht gelingen, können Sie diesen Link auch mit einer " +
        "PIN sichern.",
    },

    requested: {
      title: "PIN festlegen",
      text:
        "Bitte nutzen Sie die folgende PIN, um dieses dossier zukünftig öffnen zu können. Bewahren Sie die PIN " +
        "sicher auf, beispielsweise in einem Passwortmanager. Sollte sie doch abhanden kommen, kontaktieren Sie " +
        "bitte Ihre Kontaktperson.",
    },

    confirmed: {
      title: "Erfolg",
      text:
        "Das hat funktioniert!\n" +
        "Wir leiten Sie jetzt ins dossier weiter.\n" +
        "Sollten Sie die PIN verlieren, kontaktieren Sie ihre Kontaktperson.",
    },

    required: {
      title: "PIN Eingabe",
      text: "Geben Sie hier die PIN für dieses dossier ein.",
    },
  },
  error: {
    required: "Wert verpflichtend",
    invalidFormat: "Ungültiges Format",
    invalidDate: "Ungültiges Datum",
    userNameTaken: "Name bereits vergeben",
  },
  pref: {
    developerMode: "Entwicklermodus",
  },
  validFormats: "Gültige Formate",
  dossier: {
    dossier: "dossier | dossiers",
    overview: "dossier-Übersicht",
    management: "dossier-Verwaltung",
    create: "@:dossier.dossier anlegen",
    createdAt: "angelegt am",
    createdBy: "angelegt von",
    chooseSuffix: "Wähle Buchstaben:",
    share: "@:dossier.dossier teilen",
    noLinks: "Keine Links vorhanden",
    level: "Level",
    saving: "Speichere @:dossier.dossier …",
    save: "@:dossier.dossier speichern",
    unsavedChangesLastSave:
      "Ungespeicherte Änderungen vorhanden, zuletzt gespeichert:",
    lastChange: "letzte Aktualisierung",
    ofDate: "vom",
    conflictingEditDetected:
      "Achtung: Jemand anderes bearbeitet dieses @:dossier.dossier momentan, alle Ihre Änderungen werden verloren gehen.",
    cannotSave: "Speichern nicht möglich",
  },
  edit: {
    rowDelete: "Zeile löschen",
    rowAdd: "Zeile hinzufügen",
    fileDelete: "Datei löschen",
    fileAdd: "Datei hinzufügen",
    access: "Zugriff",
    file: {
      preview: "Vorschau",
      label: "Beschriftung",
      source: "Quelle",
      file: "Datei",
      date: "Datum",
    },
  },
  workstation: {
    workstation: "Arbeitsstation|Arbeitsstationen",
    manage: "Arbeitsstationen verwalten",
    workstationsOf: "Arbeitsstationen von",
    add: "Arbeitsstation hinzufügen",
    created: "Arbeitsstation angelegt, bitte Link kopieren:",
    openQr: "Alternativ: per QR-Code auf anderes Gerät übertragen:",
    name: "Name der Arbeitsstation",
    linkOnce:
      "Achtung: Der Link kann nur einmalig zum Aktivieren einer einzelnen Arbeitsstation (Kombination aus Gerät und Browser) verwendet werden. Jede zusätzliche Arbeitsstation braucht einen zusätzlichen Link.",
    current: "Aktuelle Arbeitsstation",
    delete: "Arbeitsstation löschen",
    deleteThis:
      "Diese Aktion wird sofort wirksam und kann nicht rückgängig gemacht werden. Folgende Arbeitsstation löschen: ",
  },
  optional: "Optional",
  lastChangeAt: "letzte Änderung am",
  stateAt: "Stand vom",
  superuser: "Superuser | Superuser",
  profiler: "Profiler | Profiler",
  analyst: "Analyst | Analysten",
  lastEdit: "zuletzt bearbeitet ",
  share: "Teilen",
  open: "Öffnen",
  cancel: "Abbrechen",
  doneBy: "von",
  assign: "Zuordnen",
  assignProfiler: "Profiler zuweisen",
  role: "Rolle",
  assignedTo: "zugeordnet zu",
  nobody: "Niemand",
  userSelect: {
    noRole: "Kein @:{role} zugewiesen",
  },
  closeWindow: "Fenster schliessen",
  lockWorkstation: "Arbeitsstation sperren",
  link: {
    link: "Link",
    create: "Neuen Link erstellen",
    isInactive: "ist deaktiviert",
    deactivate: "Teilen beenden",
    createInsecure: "Unsicherer Link",
    createInsecureHelp:
      "Benötigt weder Passkey noch PIN zum Aufrufen des Links. Kann nicht wieder ausgeschaltet werden.",
  },
  deactivate: "deaktivieren",
  create: "anlegen",
  select: "Auswählen",
  currentUser: "Aktueller Nutzer",
  user: {
    user: "Benutzer",
    name: "Name",
    type: "Benutzer-Typ",
    management: "Benutzerverwaltung",
    overview: "Benutzerübersicht",
    create: "@:user.user anlegen",
    edit: "@:user.user bearbeiten",
    reset: "Benutzer zurücksetzen",
    resetQuestion: "Benutzer zurücksetzen?",
    resetConfirm:
      "Alle Arbeitsstationen des Benutzers werden gelöscht und die Verschlüsselungsschlüssel werden zurückgesetzt, dann wird eine neue erste Arbeitsstation erzeugt.",
    resetDone: "Der Benutzer wurde zurückgesetzt.",
    createdDone: "Der Benutzer wurde angelegt.",
    initialWorkstation:
      "Mit dem folgenden Link kann der Benutzer seine erste Arbeitsstation aktivieren. Bitte diesen Link dem Benutzer zur Verfügung stellen:",
    linkOnce:
      "Achtung: Der Link kann nur einmalig zum Aktivieren einer einzelnen Arbeitsstation (Kombination aus Gerät und Browser) verwendet werden. Links für weitere Arbeitsstationen kann der Benutzer selbständig anlegen.",
  },
  logout: "Logout",
  block: {
    headline: "Überschrift",
    width: "Breite",
    type: "Art des Blocks",
    types: {
      Text: "Text (einfach)",
      RichText: "Text",
      KeyValueTable: "Tabelle (2 Spalten)",
      Chronology: "Chronologie",
      FileCollection: "Dateisammlung",
      Gallery: "Bildergallerie",
    },
    move: "Block verschieben",
    delete: "Block löschen",
  },
  help: {
    webauthn: {
      registration: {
        firstTime:
          "Da Sie diesen Link das erste Mal aufrufen, müssen Sie sich für den sicheren Zugang registrieren.",
        localBiometry:
          "Je nach Gerät werden Sie jetzt aufgefordert, den Zugang mit einem biometrischen Merkmal (wie Face-ID) oder einer PIN zu sichern. Diese werden nicht an den Server übertragen sondern verbleiben auf Ihrem Gerät!",
        linkLocked:
          "Nach Abschluss dieser Registrierung kann der geheime Link von keiner anderen Person aufgerufen werden.",
      },
      authentication: {
        localBiometry:
          "Je nach Gerät werden Sie jetzt aufgefordert, den Zugang mit einem biometrischen Merkmal (wie Face-ID) oder einer PIN zu bestätigen. Diese werden nicht an den Server übertragen sondern verbleiben auf Ihrem Gerät!",
      },
      passkeyNote:
        "Technischer Hintergrund: Wir verwenden Passkeys um den Zugang zu sichern.",
      clickHereForFocus:
        "Bitte klicken oder tippen Sie hier um den Vorgang fortzusetzen.",
    },
    networkError: {
      hint: "Die letzte Aktion war nicht erfolgreich. Sollte dies wiederholt auftreten, laden Sie bitte die Seite neu. Falls Sie Änderungen vorgenommen haben, sind diese möglicherweise nicht gespeichert worden.",
    },
  },
  closeError: "Fehlermeldung schließen",
  networkError: "Fehler bei der Kommunikation mit dem Server",
  copiedToClipboard: "In die Zwischenablage kopiert",
  dateFormat: {
    displayPlaceholder: "TT.MM.JJJJ",
    displayAllowed: [
      "TT.MM.JJJJ",
      "TTMMJJJJ",
      "TT,MM,JJJJ",
      "TT/MM/JJJJ",
      "TT-MM-JJJJ",
    ],
  },
  meta: {
    persons: "Personen",
    companies: "Unternehmen",
    title: "Dossier-Titel",
  },
  eventsType: {
    socialMedia: "Soziale Medien",
    social: "SocialMedia-Account",
    post: "SocialMedia-Post",
    image: "Bild",
    document: "Dokument",
    website: "Webseite",
    press: "Presse",
    company_db: "Unternehmensdatenbanken",
    person_db: "Personendatenbanken",
    ereignisse: "Ereignisse",
    other: "Ereignis",
  },
  pressType: {
    press: "Presseberichte",
    company_db: "Unternehmensdatenbanken",
    person_db: "Personendatenbanken",
    image: "Image",
    document: "Dokument",
    account: "Soziale Medien",
    post: "Soziale Medien",
    website: "Web",
    other: "Web",
  },
  credential: {
    credential: "Passkey | Passkeys",
    credentialsOf: "Passkeys von",
    setName: "Namen vergeben",
    add: "Passkey registrieren",
  },
  sidebarGroup: {
    person: "Person",
    company: "Unternehmen",
  },
  sections: {
    eingabedaten: "Eingabedaten",
    aufgaben: "Aufgaben",
    person: "Person",
    unternehmen: "Unternehmen",
    verbindungsanalyse: "Verbindungsanalyse",
    archive: "Presseberichte",
    bericht: "Zusammenfassung",
    darkWeb: "Dark Web",
    machineLearningTools: "Machine Learning Tools",
    featuredFoto: "Featured Foto",
    kennzahlen: "Kennzahlen",
    googleTrends: "Google Suchtreffer",
    ereignisse: "Ereignisse",
  },
}
