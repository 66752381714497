import { computed, Ref, ref } from "vue"
import { defaults } from "lodash"

type Job = {
  queued: number
  done: number
  scale: number
  maxPercent?: number
  estimated?: boolean
}

export function useProgress<T extends string>() {
  const jobs = ref({}) as Ref<Record<T, Job>>

  const percent = computed(() => {
    let donePercent = 0
    let allocatedPercent = 0
    let queued = 0
    let done = 0

    for (const job of Object.values(jobs.value) as Job[]) {
      if (job.maxPercent === undefined) {
        queued += job.queued * job.scale
        done += Math.min(job.done, job.queued) * job.scale
      } else {
        allocatedPercent += job.maxPercent
        if (job.queued) {
          donePercent +=
            (Math.min(job.done, job.queued) / job.queued) * job.maxPercent
        }
      }
    }

    let retval = 0

    if (allocatedPercent >= 100) {
      retval = (donePercent / allocatedPercent) * 100
    } else {
      const missingPercent = 100 - allocatedPercent
      let totalPercent = donePercent
      if (queued) {
        totalPercent += (done / queued) * missingPercent
      }
      retval = totalPercent
    }

    return Math.round(retval)
  })

  function _assertJob(jobName: T, params: Partial<Job> = {}) {
    if (!jobs.value[jobName]) {
      jobs.value[jobName] = defaults(params, {
        queued: 0,
        done: 0,
        scale: 1,
      })
    }
  }

  function advise(
    jobName: T,
    scale: number = 1,
    maxPercent: number | undefined = undefined,
  ) {
    _assertJob(jobName, { scale, maxPercent, estimated: true })
    jobs.value[jobName].scale = scale
    jobs.value[jobName].maxPercent = maxPercent
  }

  function queue(jobName: T, count: number, estimate: boolean = false) {
    _assertJob(jobName, { estimated: true })
    if (estimate) {
      if (jobs.value[jobName].estimated) {
        // Add to estimate
        jobs.value[jobName].queued += count
      }
    } else {
      // Real value
      if (jobs.value[jobName].estimated) {
        // Clear estimate
        jobs.value[jobName].queued = count
      } else {
        // Queue normally
        jobs.value[jobName].queued += count
      }
    }
  }

  function progress(jobName: T, count: number) {
    if (!jobs.value[jobName]) {
      console.log(`Warning: Reporting progress on unqueued job ${jobName}`)
    }
    _assertJob(jobName)
    jobs.value[jobName].done += count
  }

  function reset() {
    jobs.value = {} as Record<T, Job>
  }

  async function call<R>(
    jobName: T,
    handler: () => Promise<R>,
    interval: number = 100,
    expected: number = 10,
  ) {
    queue(jobName, expected)
    const t = window.setInterval(() => progress(jobName, 1), interval)
    try {
      return await handler()
    } finally {
      clearTimeout(t)
      jobs.value[jobName].done = expected
    }
  }

  return { jobs, percent, advise, queue, progress, call, reset }
}
