import { h } from "vue"
import type { IconSet, IconProps } from "vuetify"

import smartBlockIcon from "@/common/assets/smartBlockIcon.vue"
import standardBlockIcon from "@/common/assets/standardBlockIcon.vue"

const customSvgNameToComponent: any = {
  smartBlockIcon,
  standardBlockIcon,
}

const customSVGs: IconSet = {
  component: (props: IconProps) =>
    h(props.tag, [
      h(customSvgNameToComponent[props.icon as string], {
        class: "v-icon__svg",
      }),
    ]),
}

export { customSVGs }
