<script lang="ts" setup>
import {
  EntityDataEntityIdentifier,
  EntityDataInfo,
  ImageInfo,
} from "@/common/store/dossier/types"
import LazyImage from "@/common/components/LazyImage.vue"
import EntityStackImages from "@/common/components/view/EntityStackImages.vue"
import ShortDate from "@/common/components/ShortDate.vue"
import { computed } from "vue"
type GalleryEntry = {
  cols: number
  image: ImageInfo
}
const props = defineProps<{
  list?: GalleryEntry[]
  imageList?: ImageInfo[]
  entities?: {
    [id: EntityDataEntityIdentifier]: EntityDataInfo
  }
}>()

const GalleryList = computed(() => {
  if (props.list?.length) return props.list
  return props.imageList?.map((img) => {
    return {
      cols: 6,
      image: img,
    }
  })
})
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="(entry, i) of GalleryList" :key="i" :cols="entry.cols">
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            :elevation="isHovering ? 12 : 2"
            :class="{ 'on-hover': isHovering }"
            v-bind="props"
          >
            <lazy-image :image="entry.image" cover :allow-enlarge="true">
              <v-fade-transition>
                <v-card-title
                  v-if="isHovering"
                  class="card-title cursor-pointer d-flex text-h6 text-white flex-column justify-center align-center h-100"
                >
                  <p class="ma-0 text-body-1 font-weight-bold text-wrap">
                    {{ entry.image.title }}
                  </p>
                  <p class="text-caption font-weight-medium mb-2">
                    <short-date
                      :date="new Date(entry.image.date)"
                      v-if="entry.image.date"
                    />
                  </p>
                </v-card-title>
              </v-fade-transition>
              <template #title>
                <div>
                  <EntityStackImages
                    v-if="entities"
                    :entities="entities"
                    :usedEntities="entry.image.entities"
                  />
                  {{ entry.image.title }}
                  <span v-if="entry.image.date"
                    >(<short-date :date="new Date(entry.image.date)" />)</span
                  >
                </div>
              </template>
            </lazy-image>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.v-card {
  &.on-hover {
    .card-title {
      background-color: rgba(var(--v-theme-primary), 0.9);
    }
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
</style>
