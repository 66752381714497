<template>
  <v-card-text>
    <div class="inner-grid pt-10">
      <CardBlock style="grid-column: span 4">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-sheet
                class="d-flex flex-column justify-center align-start"
                color="transparent"
              >
                <v-avatar
                  class="elevation-2"
                  color="grey-darken-1"
                  size="180"
                  cover
                >
                  <lazy-image
                    v-if="entity.featuredImage"
                    :image="entity.featuredImage"
                    cover
                  />
                  <v-icon v-else :icon="mdiAccount" size="128" />
                </v-avatar>

                <h3 class="text-h5">{{ entity.title }}</h3>
                <h4
                  class="text-h6"
                  v-for="(alias, i) of entity.aliases"
                  :key="'alias' + i"
                  style="scale: 0.8; transform: translateX(-10px)"
                >
                  <span style="font-variant: small-caps">aka</span>
                  {{ alias.value }}
                </h4>
              </v-sheet>
              <SocialMedia
                noWidthOnCount
                elementSpacing="mr-2"
                alignment="start"
                :list="
                  view.social_media.filter((item) =>
                    item.entities.includes(entity.id),
                  )
                "
              >
                <v-menu open-on-hover v-if="entity.phone_numbers.length">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mr-2"
                      size="34"
                      rounded="xl"
                      :icon="mdiPhone"
                      v-bind="props"
                      variant="flat"
                      color="primary"
                    />
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(entry, i) of entity.phone_numbers"
                      :key="i"
                    >
                      {{ entry.key }}:
                      <a
                        :href="'tel:' + entry.value"
                        class="text-primary text-decoration-none"
                      >
                        {{ entry.value }}
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu open-on-hover v-if="entity.emails.length">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mr-2"
                      size="34"
                      rounded="xl"
                      :icon="mdiAt"
                      v-bind="props"
                      variant="flat"
                      color="primary"
                    />
                  </template>
                  <v-list>
                    <v-list-item v-for="(entry, i) of entity.emails" :key="i">
                      {{ entry.key }}:
                      <a
                        :href="'mailto:' + entry.value"
                        class="text-primary text-decoration-none"
                      >
                        {{ entry.value }}
                      </a>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </SocialMedia>
            </v-col>
            <v-col cols="6">
              <sourced-value-table
                :items="informationTable"
                v-if="informationTable.length > 0"
            /></v-col>
          </v-row>
        </v-container>
      </CardBlock>

      <CardBlock
        style="grid-column: span 4"
        v-if="filteredSocials.length"
        :title="`${entity.title}
        in
        sozialen Medien`"
      >
        <social-media-chart :model-value="filteredSocials" />
      </CardBlock>
      <div style="grid-column: span 4">
        <NewsBlock
          v-if="pressData.length > 0"
          maxHeight="100% !important"
          for-show
          :list="pressData"
          :with-drawer="false"
        />
      </div>
      <CardBlock
        title="Fotos"
        style="grid-column: span 4"
        v-if="imageList.length > 0"
      >
        <GalleryBlock :imageList="imageList" />
      </CardBlock>
    </div>
  </v-card-text>
</template>

<script setup lang="ts">
import {
  DossierView,
  EntityDataInfo,
  SourcedValue,
} from "@/common/store/dossier"
import CardBlock from "@/common/components/view/CardBlock.vue"
import GalleryBlock from "@/common/components/view/GalleryBlock.vue"
import LazyImage from "@/common/components/LazyImage.vue"
import SourcedValueTable from "@/common/components/view/SourcedValueTable.vue"
import { computed } from "vue"
import { mdiAccount, mdiAt, mdiPhone } from "@mdi/js"
import SocialMedia from "@/common/components/view/SocialMedia.vue"
import SocialMediaChart from "@/common/components/view/SocialMediaChart.vue"
import NewsBlock from "./NewsBlock.vue"

const props = defineProps<{
  view: DossierView
  entity: EntityDataInfo & { type: "person" }
}>()

const pressData = computed(() => {
  return props.view.web_snapshots.filter(
    (x) => x.entities?.includes(props.entity.id),
  )
})

const informationTable = computed(() => {
  const retval = []
  if (props.entity.birthDate) {
    retval.push(["Geburtsdatum", [props.entity.birthDate]])
  }
  for (const addr of props.entity.addresses || []) {
    retval.push([
      addr.value.label,
      [{ value: addr.value.address, source: addr.source }],
    ])
  }
  return [
    ...retval,
    ...Object.entries(props.entity.otherInformation).sort(),
  ] as [string, SourcedValue<any>[]][]
})

const filteredSocials = computed(() =>
  props.view.social_media.filter(
    (item) =>
      item.entities.includes(props.entity.id) &&
      parseInt(`${item?.followers}` || "0") > 0,
  ),
)

const imageList = computed(() => {
  return props.view.images.filter(
    (item) =>
      !item.globalFeatured &&
      !item.featured &&
      item.entities.includes(props.entity.id),
  )
})
</script>

<style scoped lang="scss">
.inner-grid {
  display: grid;
  row-gap: 16px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ". .";
}
</style>
