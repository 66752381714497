<template>
  <vue-word-cloud
    style="padding-top: 70%"
    :words="wordCloud"
    font-family="Roboto"
    :spacing="0.1"
    :font-size-ratio="4"
    :color="wordCloudItemColor"
  />
</template>
<script lang="ts" setup>
import { DossierView } from "@/common/store/dossier/types"
import { computed } from "vue"
import { useTheme } from "vuetify/lib/framework.mjs"
// @ts-ignore
import VueWordCloud from "vuewordcloud"
import * as he from "he"
const props = withDefaults(
  defineProps<{ view: DossierView; count?: number | null }>(),
  { count: 25 },
)
const theme = useTheme()

function* filterWords(removeWords: Set<string>, source: string[]) {
  for (const entry of source) {
    for (const word of entry.match(/[\wöüäßÖÄÜ]+/g) || []) {
      if (removeWords.has(word.toLowerCase())) {
        continue
      }
      if (word.match(/^\d+$/)) {
        continue
      }
      yield word
    }
  }
}

function* allWords(view: DossierView) {
  const removeWords = new Set<string>()
  for (const entity of Object.values(view.entities)) {
    removeWords.add(entity.title.toLowerCase())
    for (const word of entity.title.match(/[\wöüäßÖÄÜ]+/g) || []) {
      removeWords.add(word.toLowerCase())
    }
  }
  yield* filterWords(
    removeWords,
    view.events.map((event) => event.title),
  )
  yield* filterWords(
    removeWords,
    view.reports
      .map((report) => report.richContent.replace(/(<([^>]+?)>)/gi, ""))
      .map((s) => he.decode(s)),
  )
}

function wordCloudItemColor([, weight]: [any, number]) {
  return weight >= 1.05 ? theme.current.value.colors.primary : "black"
}
const wordCloud = computed(() => {
  if (!props.view) {
    return []
  }

  const bins = new Map<string, number>()

  for (const word of allWords(props.view!)) {
    bins.set(word, 1 + (bins.get(word) || 0))
  }

  const result1 = Array.from(bins.entries())
  const stems = new Map<string, [string, number][]>()

  function findStem(word: string) {
    return word.replace(/e[nr]|s$/i, "").toUpperCase()
  }

  for (const [word, count] of result1) {
    if (word.length < 5) {
      continue
    }
    const stem = findStem(word)
    if (stems.has(stem)) {
      stems.get(stem)!.push([word, count])
    } else {
      stems.set(stem, [[word, count]])
    }
  }
  for (const counts of stems.values()) {
    counts.sort(([_a, a], [_b, b]) => b - a)
  }
  const result2: [string, number][] = []
  for (const [word, count] of result1) {
    const stem = findStem(word)
    if (stems.has(stem)) {
      const stemmedItems = stems.get(stem)!
      if (word == stemmedItems[0][0]) {
        result2.push([
          stemmedItems[0][0],
          stemmedItems.map((i) => i[1]).reduce((acc, cur) => acc + cur, 0),
        ])
      }
    } else {
      result2.push([word, count])
    }
  }

  const max = Math.max(...result2.map(([word, count]) => count))
  const result = result2
    .filter(([word, count]) => word.length > 4)
    .map(([word, count]) => [
      word,
      count === max ? Math.pow(count, 1 / 1.2) : count,
    ])
    .map(([word, count]) => [word, Math.pow(Number(count), 1 / 0.9)]) as [
    string,
    number,
  ][]
  if (props.count === null || props.count < 1) {
    return result
  } else {
    return result.sort((a, b) => b[1] - a[1]).slice(0, props.count)
  }
})
</script>
