<template>
  <v-table>
    <tbody>
      <tr v-for="(entry, i) of displayData" :key="i">
        <th
          v-if="entry.rowspan !== null"
          :rowspan="entry.rowspan"
          style="width: 130px"
        >
          {{ entry.key }}
        </th>
        <td>
          <v-tooltip :text="entry.source">
            <template v-slot:activator="{ props }">
              <a
                v-if="entry.source && isValidUrl(entry.source)"
                class="text-primary"
                :href="entry.source"
                target="_blank"
                v-bind="props"
              >
                <data-value-display
                  :v="entry.value"
                  :hover-style="{ dateStyle: 'full' }"
                />
              </a>
              <template v-else>
                <data-value-display
                  :v="entry.value"
                  :hover-style="{ dateStyle: 'full' }"
                />
              </template>
            </template>
          </v-tooltip>
        </td>
        <td v-if="entry.source">
          <event-tooltip :source="entry.source" />
        </td>
      </tr>
    </tbody>
  </v-table>
</template>
<script lang="ts" setup>
import { SourcedValue } from "@/common/store/dossier"
import { computed } from "vue"
import EventTooltip from "@/common/components/view/EventTooltip.vue"
import { isValidUrl } from "@/common/lib/util"
import DataValueDisplay from "@/common/components/view/DataValueDisplay.vue"

function stringifyValue(value: any) {
  if ("toLocaleString" in value) {
    return (value as Date).toLocaleString()
  } else {
    return `${value}`
  }
}

const props = defineProps<{ items: [string, SourcedValue<any>[]][] }>()
const displayData = computed(() => {
  const retval = [] as {
    key: string
    value: string
    source?: string
    rowspan: number | null
  }[]
  for (const [key, entries] of props.items) {
    for (const [i, entry] of entries.entries()) {
      retval.push({
        key,
        ...entry,
        rowspan: i === 0 ? entries.length : null,
      })
    }
  }
  return retval
})
</script>
<style lang="scss">
table {
  th {
    font-weight: bold;
  }
  td {
    padding: 0 !important;
  }
}
</style>
