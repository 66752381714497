import { Ref, ref } from "vue"
import { useDossierStore } from "@/common/store/dossier"

import { until } from "@vueuse/core"
import {
  DossierContents,
  DossierOverview,
  DossierView,
  LayoutType,
  LayoutList,
} from "@/common/store/dossier/types"

export function useDossierData(dossierId: string): {
  dossierContents: Ref<DossierContents | null>
  dossierOverview: Ref<DossierOverview | null>
  dossierPreview: Ref<DossierView | null>
  loadInProgress: Ref<boolean>
  layout: Ref<LayoutType>
} {
  const dossierStore = useDossierStore()
  const dossierContents: Ref<DossierContents | null> = ref(null)
  const dossierOverview: Ref<DossierOverview | null> = ref(null)
  const dossierPreview: Ref<DossierView | null> = ref(null)
  const layout: Ref<LayoutType> = ref("viel")
  const loadInProgress = ref(true)

  const loadDossierData = async () => {
    if (!dossierId) {
      return
    }

    loadInProgress.value = true

    try {
      dossierContents.value = await dossierStore.getDossierContents(
        dossierStore.urlForId(dossierId),
      )
      dossierOverview.value = await until(
        dossierStore.getDossierOverview(dossierStore.urlForId(dossierId)),
      ).toBeTruthy()
      if (dossierContents.value && dossierOverview.value) {
        dossierPreview.value = await dossierStore.loadDossierPreview(
          dossierOverview.value,
          dossierContents.value,
        )
      }
    } catch (error) {
      console.error("Error loading dossier:", error)
    } finally {
      layout.value = dossierOverview.value?.display?.layout || "viel"
      if (!LayoutList.includes(layout.value)) {
        layout.value = LayoutList[0]
      }

      loadInProgress.value = false
    }
  }

  loadDossierData()

  return {
    dossierContents,
    dossierOverview,
    loadInProgress,
    dossierPreview,
    layout,
  }
}
