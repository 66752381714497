<script lang="ts" setup>
import { EntityDataInfo } from "@/common/store/dossier"
import { Dictionary } from "lodash"
import LazyImage from "@/common/components/LazyImage.vue"
import CardBlock from "./CardBlock.vue"
import { mdiAccount, mdiWarehouse } from "@mdi/js"
import { computed, ref } from "vue"
const props = defineProps<{
  entities: Dictionary<EntityDataInfo[]>
}>()

const showAllEntities = ref<Dictionary<boolean>>({
  person: false,
  company: false,
})

const toggleEntities = (entityType: string) => {
  showAllEntities.value[entityType] = !showAllEntities.value[entityType]
}

const visibleEntities = computed(() => {
  return Object.entries(props.entities).reduce(
    (acc, [entityType, entities]) => {
      acc[entityType] = showAllEntities.value[entityType]
        ? entities
        : entities.slice(0, 4)
      return acc
    },
    {} as Dictionary<EntityDataInfo[]>,
  )
})

defineEmits(["showEntity"])
</script>

<template>
  <CardBlock
    :elevation="0"
    title="Personen"
    v-if="visibleEntities.person"
    tooltip="Auswahl des näheren Umfelds"
  >
    <v-row>
      <v-col
        cols="6"
        v-for="(person, index) in visibleEntities.person"
        :key="index"
      >
        <v-card
          elevation="0"
          @click="$emit('showEntity', person.id)"
          class="px-4 py-1 d-flex flex-column align-center w-100 cursor-pointer"
        >
          <v-avatar size="90" color="tertiary">
            <lazy-image
              v-if="person.featuredImage"
              :image="person.featuredImage"
              cover
            />
            <v-icon v-else :icon="mdiAccount" :size="50"></v-icon>
          </v-avatar>
          <h4 class="text-subtitle-2 text-center w-100">
            {{ person.title }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      variant="outlined"
      class="w-100 bg-surface"
      v-if="entities.person.length > 4"
      @click="toggleEntities('person')"
    >
      {{ showAllEntities.person ? "Weniger" : "Mehr" }}
    </v-btn>
  </CardBlock>

  <CardBlock
    :elevation="0"
    title="Unternehmen"
    v-if="visibleEntities.company"
    tooltip="Auswahl verbundene Körperschaften"
  >
    <v-row>
      <v-col
        cols="6"
        v-for="(company, index) in visibleEntities.company"
        :key="index"
      >
        <v-card
          elevation="0"
          class="px-4 py-1 d-flex flex-column align-center w-100 cursor-pointer"
          @click="$emit('showEntity', company.id)"
        >
          <v-avatar size="90" color="tertiary">
            <lazy-image
              :image="company.featuredImage"
              cover
              v-if="company.featuredImage"
            />
            <v-icon v-else :icon="mdiWarehouse" :size="50"></v-icon>
          </v-avatar>
          <h4 class="text-subtitle-2 text-center text-truncate w-50">
            {{ company.title }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      variant="outlined"
      class="w-100 bg-surface"
      v-if="entities.company.length > 4"
      @click="toggleEntities('company')"
    >
      {{ showAllEntities.company ? "Weniger" : "Mehr" }}
    </v-btn>
  </CardBlock>
</template>
