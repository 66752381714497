<script lang="ts" setup>
import { ExternalWidgetEntry } from "@/common/store/dossier"
import { Ref, onMounted, ref } from "vue"

const model = defineModel<ExternalWidgetEntry[]>()
const widgetRef: Ref<HTMLDivElement | undefined> = ref()

onMounted(() => {
  const widgets = model.value
  if (widgets && widgets.length && widgetRef && widgetRef.value) {
    widgets.forEach((widget) => {
      const iframe = document.createElement("iframe")
      iframe.srcdoc = widget.code
      widgetRef.value?.appendChild(iframe)
    })
  }
})
</script>

<template>
  <div class="widgets" ref="widgetRef" style="width: 100%; height: 100%"></div>
</template>

<style lang="scss">
.widgets {
  height: 100%;
  width: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
