import {
  ArchiveType,
  BarChartOption,
  ChapterType,
  Section,
} from "@/common/store/dossier/types"

export const _DEFAULT_ARCHIVE = {
  type: "Archive",
  meta: {
    headline: "HTML Archiv",
    pos: [5, 0],
    width: 12,
    order: 4,
  },
  body: {
    entries: [],
  } as ArchiveType,
} as Section<"Archive">

export const _DEFAULT_NETWORK_ANALYSIS = {
  type: "NetworkAnalysis",
  meta: {
    headline: "Verbindungsanalyse",
    pos: [4, 0],
    width: 12,
    order: 3,
  },
  body: {
    person_person: {},
    person_company: {},
    company_company: {},
  },
} as Section<"NetworkAnalysis">

export const _DEFAULT_EXTERNAL_WIDGETS = {
  type: "ExternalWidgets",
  meta: {
    headline: "External Widgets",
    pos: [9, 0],
    width: 12,
    order: 10,
  },
  body: {
    widgets: [
      {
        title: "Google Trends",
        type: "google_trends",
        code: "",
      },
    ],
  },
} as Section<"ExternalWidgets">

export const _DEFAULT_KPI_BAR_CHART_LEVELS = [
  [0, "keine"],
  [1, "wenig"],
  [2, "viel"],
  [3, "sehr viel"],
] as BarChartOption[]

export const DEFAULT_SECTIONS: Section<ChapterType>[] = [
  {
    type: "RichText",
    meta: {
      headline: "Eingabedaten",
      pos: [0, 1],
      width: 6,
      order: -1,
    },
    body: {
      content: "",
    },
  },
  {
    type: "RichText",
    meta: {
      headline: "Aufgaben",
      pos: [0, 2],
      width: 6,
      order: -1,
    },
    body: {
      content: "",
    },
  },
  _DEFAULT_NETWORK_ANALYSIS,
  _DEFAULT_ARCHIVE,
  {
    type: "RichText",
    meta: {
      headline: "Zusammenfassung",
      pos: [6, 0],
      width: 12,
      order: 5,
    },
    body: {
      content: "",
    },
  },
  {
    type: "RichText",
    meta: {
      headline: "Dark Web",
      pos: [7, 0],
      width: 6,
      order: 6,
    },
    body: {
      content: "",
    },
  },
  {
    type: "RichText",
    meta: {
      headline: "Machine Learning Tools",
      pos: [7, 1],
      width: 6,
      order: 7,
    },
    body: {
      content: "",
    },
  },

  {
    type: "FeaturedPhoto",
    meta: {
      headline: "Featured Foto",
      pos: [8, 0],
      width: 6,
      order: 8,
    },
    body: [],
  },

  _DEFAULT_EXTERNAL_WIDGETS,
  // {
  //   type: "Chronology",
  //   meta: {
  //     headline: "Ereignisse",
  //     pos: [10, 0],
  //     width: 12,
  //     order: 11,
  //   },
  //   body: [
  //     ["", "", "", false],
  //     ["", "", "", false],
  //     ["", "", "", false],
  //     ["", "", "", false],
  //     ["", "", "", false],
  //   ],
  // },
  {
    type: "CommentsBlock",
    meta: {
      headline: "Kommentare",
      pos: [10, 0],
      width: 12,
    },
    body: [
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
      ["", ""],
    ],
  },
]
