<script lang="ts" setup>
import { useDossierStore } from "@/common/store/dossier"
import { Ref, computed, defineAsyncComponent, defineComponent, ref } from "vue"
import { defang } from "@/common/lib/util"
import { iterateDossier } from "@/common/store/dossier/util"
import CardBlock from "@/common/components/view/CardBlock.vue"
import { useDossierData } from "./useDossierData"
import KnappLayout from "./layouts/knapp.vue"
import VielLayout from "./layouts/viel.vue"
import ShortDate from "@/common/components/ShortDate.vue"
import { storeToRefs } from "pinia"
import { usePrefsStore } from "@/common/store/prefs"
const layoutOptions = {
  knapp: KnappLayout,
  viel: VielLayout,
}

const props = withDefaults(
  defineProps<{ dossierId: string; preview?: boolean }>(),
  { preview: false },
)

const dossierId: Ref<string> = computed(() => props.dossierId)
const dossierStore = useDossierStore()

const { developerMode } = storeToRefs(usePrefsStore())

const networkPositionsOut = ref(undefined as any)

const { dossierContents, dossierPreview, layout } = useDossierData(
  dossierId.value,
)
</script>

<template>
  <div>
    <div v-if="!dossierPreview">
      <v-navigation-drawer permanent app>
        <v-sheet
          class="d-flex flex-column justify-center align-center pb-4"
          color="transparent"
        >
          <v-skeleton-loader
            type="ossein"
            height="180"
            width="180"
            class="mt-4 elevation-2"
          />
          <v-skeleton-loader
            type="heading"
            class="text-h5 text-center my-2"
            width="200"
          />
          <v-skeleton-loader type="list-item-avatar@5" />
        </v-sheet>
      </v-navigation-drawer>
      <v-main
        class="ma-4"
        style="
          display: grid;
          place-items: center;
          position: absolute;
          min-height: 95%;
          width: 100%;
        "
      >
        <v-progress-circular
          color="primary"
          size="240"
          width="20"
          :model-value="dossierStore.loading.percent"
          :indeterminate="dossierStore.loading.percent === 0"
          >{{ dossierStore.loading.percent }}&thinsp;%</v-progress-circular
        >
      </v-main>
    </div>
    <div v-else>
      <component
        :is="layoutOptions[layout]"
        :dossierPreview="dossierPreview"
        :preview="preview"
        v-model:network-positions-out="networkPositionsOut"
      >
      </component>
      <v-main v-if="preview && developerMode">
        <pre style="border: inset 3px blue; margin: 3px; padding: 3px">{{
          JSON.stringify(defang(networkPositionsOut), undefined, 2)
        }}</pre>
        <pre>{{ JSON.stringify(defang(dossierPreview), undefined, 2) }}</pre>
        <hr />
        <!-- <pre>{{ JSON.stringify(dossierOverview, undefined, 2)}}</pre>
            <pre>{{ JSON.stringify(dossierContents, undefined, 2)}}</pre> -->
        <pre>{{
          JSON.stringify(
            defang(Array.from(iterateDossier(dossierContents!))),
            undefined,
            2,
          )
        }}</pre>
      </v-main>
      <div class="bottom-bar px-4 py-2">
        <h3 class="text-subtitle-2">
          <template v-if="preview"><b>Preview</b>: </template
          >{{ $t("dossier.dossier") }}

          {{ dossierPreview!.meta.code_name }}
          {{ $t("dossier.ofDate") }}
          <short-date
            location="bottom"
            :date="dossierPreview!.meta.created_at!"
          />
        </h3>
      </div>
    </div>
  </div>
</template>
<style>
.bottom-bar {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
}
</style>
