import type {
  EndorsementContentFactory,
  ExtractEndorseDataType,
  RootOfTrust,
} from "./types"
import { User } from "@/common/store/user"
import { b64encode } from "@/common/lib/encoding"
import { EntityPrivateBox } from "./box"

export class CryptoManager {
  public constructor(
    protected readonly entityBox: EntityPrivateBox,
    protected readonly currentUser: User,
    public readonly rot?: RootOfTrust,
  ) {}

  public endorse<
    T extends EndorsementContentFactory<D>,
    Y extends Omit<Required<D>, keyof Z | "endorsed_by">,
    Z extends Partial<D>,
    D = ExtractEndorseDataType<T>,
  >(
    ecFactory: T,
    createData: Y,
    additionalData: Z,
  ): Record<string, any> & Y & { endorsement: string }

  public endorse<
    T extends EndorsementContentFactory<D>,
    Y extends Omit<Required<D>, "endorsed_by">,
    D = ExtractEndorseDataType<T>,
  >(
    ecFactory: T,
    createData: Y,
  ): Record<string, any> & Y & { endorsement: string }

  public endorse<
    T extends EndorsementContentFactory<D>,
    Y extends Required<D>,
    D = ExtractEndorseDataType<T>,
  >(
    ecFactory: T,
    createData: Y,
    additionalData = undefined,
  ): Record<string, any> & Y & { endorsement: string; endorsed_by: User } {
    const data = {
      ...createData,
      ...(additionalData ?? {}),
      endorsed_by: this.currentUser,
    }
    const endorsementData = ecFactory(data)
    const endorsement = b64encode(this.entityBox.sign(endorsementData))
    return { ...createData, endorsement, endorsed_by: data.endorsed_by }
  }

  public clear() {}
}
