import plzMapping from "@/common/data/germanPlz.json"
import { zipObject } from "lodash"

export type PlzEntry = {
  plz: string
  name: string
  lat: string
  lon: string
}

export function lookupGermanPlz(plz: string): PlzEntry | null {
  const plzEntry = ((plzMapping as Record<string, Record<string, string[]>>)[
    plz.substring(0, 2)
  ] ?? {})[plz.substring(2)]
  return {
    ...zipObject(["name", "lat", "lon"], plzEntry),
    plz,
  } as PlzEntry
}
