<template>
  <div style="min-width: 200px; min-height: 200px">
    <apexchart
      type="radialBar"
      :series="series"
      :options="{ ...options, ...localOptions }"
    ></apexchart>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue"
import { KpiData } from "@/common/store/dossier"
import { useTheme } from "vuetify/lib/framework.mjs"
const theme = useTheme()

const props = withDefaults(
  defineProps<{
    modelValue: KpiData[]
    options: Record<string, any>
  }>(),
  { options: {} as any },
)

const localOptions = computed(() => ({
  colors: [
    theme.current.value.colors.accent4,
    theme.current.value.colors.accent1,
    theme.current.value.colors.accent3,
  ],
  chart: {
    type: "radialBar",
    width: "90%",
    height: "95%",
  },
  plotOptions: {
    radialBar: {
      dataLabels: {
        show: true,
        name: {
          offsetY: "115%",
          show: true,
          fontSize: "15px",
          fontWeight: 900,
        },
        value: {
          offsetY: "-8%",
          fontSize: "15px",
          show: true,
          fontFamily: "Roboto",
          formatter: function (value: number) {
            if (value == 3)
              return dataWithLabel.value.find((x) => x.value == 0)?.label
            return dataWithLabel.value.find((x) => x.value == value / 25)?.label
          },
        },
      },
    },
  },
  labels: props.modelValue.map((item) => item.dimension),
}))

const series = computed(() => {
  return props.modelValue.map((item) => (item.value != 0 ? item.value * 25 : 3))
})
const dataWithLabel = computed(() => {
  return props.modelValue.map((item) => ({
    dimension: item.dimension,
    value: item.value,
    label: item.levels?.find((level) => level.value === item.value)?.label,
  }))
})
</script>
<style>
.vue-apexcharts svg {
  overflow: visible !important;
}
</style>
