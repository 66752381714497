import { defineStore } from "pinia"
import { ref } from "vue"

export const usePrefsStore = defineStore(
  "prefs",
  () => {
    const developerMode = ref(false)
    return {
      developerMode,
    }
  },
  {
    persist: {
      enabled: true,
      strategies: [{ storage: localStorage }],
    },
    share: {
      enable: true,
      initialize: true,
    },
  },
)
