<script lang="ts" setup>
import ShortDate from "../ShortDate.vue"
import { useURLParser } from "@/common/lib/util"
import EventTooltip from "./EventTooltip.vue"
import {
  EntityDataEntityIdentifier,
  EntityDataInfo,
} from "@/common/store/dossier/types"
const props = defineProps<{
  item: any
  entities?: {
    [id: EntityDataEntityIdentifier]: EntityDataInfo
  }
}>()
const getEntitiesImages = (data: any) => {
  let entities: any = []
  data.forEach((ent: any) => {
    let img = props.entities && props.entities[ent].featuredImage
    props.entities && img && entities.push(img.thumbnailUrl)
  })
  return entities
}
const emit = defineEmits(["toggle-app-drawer"])
const toggleAppDrawer = (type: string) =>
  type === "image" && emit("toggle-app-drawer")
</script>

<template>
  <v-menu
    open-on-hover
    open-on-click
    open-delay="0"
    activator="parent"
    location="bottom"
  >
    <template v-slot:activator="{ props }"
      ><v-icon :icon="item.icon" v-bind="props"></v-icon>
      <v-badge
        class="timeline-count"
        v-if="item.count > 1"
        :content="item.count"
        color="accent6"
      >
      </v-badge>
    </template>
    <ul class="timeline-popup-menu pa-1 elevation-3">
      <v-table density="compact">
        <thead>
          <tr>
            <th colspan="2">
              {{ $t("eventsType." + item.type) }}
            </th>
          </tr>
        </thead>
        <tr v-for="child in item.items || [item]" :key="child.id">
          <td
            class="pa-2 shrink text-truncate"
            style="vertical-align: baseline"
          >
            <short-date disabled location="bottom" :date="child.date" />
          </td>
          <td class="pa-2 shrink" style="max-width: 300px">
            <template
              v-if="
                child.type === 'socialMedia' ||
                child.type === 'press' ||
                child.type === 'company_db' ||
                child.type === 'person_db' ||
                child.type === 'other'
              "
            >
              <div
                class="text-truncate"
                v-if="useURLParser(child.source).getURL.value"
                target="_blank"
              >
                {{ useURLParser(child.source).getDomainTitle.value }}
              </div>
              <a
                :href="useURLParser(child.source).getURL.value"
                class="d-block text-truncate text-primary"
                >{{ child.title }}</a
              >
            </template>
            <template v-else>
              <a
                v-if="useURLParser(child.source).isValid.value"
                class="d-block text-primary text-truncate"
                :href="child.source"
                target="_blank"
                >{{ child.source }}</a
              >

              <div
                v-else
                @click="toggleAppDrawer(child.type)"
                class="cursor-pointer"
              >
                {{ child.title }}
              </div>
            </template>
            <div
              style="width: 15px"
              v-if="child.entities && child.entities.length && entities"
            >
              <v-avatar
                size="24"
                v-for="(img, index) in getEntitiesImages(child.entities)"
                :key="index"
              >
                <v-img width="24" :src="img" :lazy-src="img" />
              </v-avatar>
            </div>
          </td>
          <td class="pa-2 shrink" v-if="child.source">
            <event-tooltip :source="child.source" />
          </td>
        </tr>
      </v-table>
    </ul>
  </v-menu>
</template>
