<script lang="ts" setup>
import { useURLParser } from "@/common/lib/util"
import { SocialMediaTypeX } from "@/common/store/dossier"
import { computed } from "vue"
import { useTheme } from "vuetify/lib/framework.mjs"
import { groupBy } from "lodash"

const theme = useTheme()
const props = withDefaults(
  defineProps<{
    modelValue: any
  }>(),
  {},
)

function groupFollowersByDomain(data: SocialMediaTypeX[]) {
  const followersByDomain = data.reduce(
    (acc: Record<string, number>, account) => {
      const domain: string = account.groupName
      const followers: number = Math.floor(Number(account.followers)) || 0
      if (followers > 0) {
        acc[domain] = (acc[domain] || 0) + followers
      }
      return acc
    },
    {},
  )

  const sortedData = Object.entries(followersByDomain)
    .sort(([, a], [, b]) => b - a)
    .filter(([, totalFollowers]) => totalFollowers > 0)
    .slice(0, 5)
    .map(([domain, totalFollowers]) => ({ domain, totalFollowers }))

  return sortedData.length
    ? {
        domains: sortedData.map(({ domain }) => domain),
        totalFollowers: sortedData.map(({ totalFollowers }) => totalFollowers),
        followersByDomain,
      }
    : {}
}

const socialGroupByDomain = computed(() => {
  return groupBy(props.modelValue, "groupName")
})
const extractedList = computed(() => {
  return groupFollowersByDomain(props.modelValue)
})
const series = [
  {
    data: extractedList.value.totalFollowers,
  },
]

const colors = computed(() => {
  const domains = extractedList.value.domains

  const getColorForDomain = (domain: string) => {
    return useURLParser(domain, false).getColor()
  }

  if (domains && domains.length > 0) {
    return domains?.map(getColorForDomain)
  }

  return [
    theme.current.value.colors.accent4,
    theme.current.value.colors.accent1,
    theme.current.value.colors.accent5,
    theme.current.value.colors.accent2,
    theme.current.value.colors.accent3,
  ]
})

const chartOptions = {
  colors: colors.value,
  chart: {
    height: "100%",
    id: "barYear",
    width: "100%",
    type: "bar",
    toolbar: {
      autoSelected: "pan",
      show: false,
    },
  },
  plotOptions: {
    bar: {
      distributed: true,
      horizontal: true,
      barHeight: 40,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: true,
    textAnchor: "start",
    formatter: function (val: any) {
      return val + " Follower"
    },
    style: {
      fontWeight: "bold",
      colors: "black",
    },
  },
  tooltip: {
    intersect: false,
    items: {
      display: "block",
    },
    custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
      const listByDomain =
        socialGroupByDomain.value[w.globals.labels[dataPointIndex]]
      const tooltipElements = listByDomain.map((item) => {
        return `<div class="d-flex  text-subtitle-2 text-primary mb-3"><svg style="background-color:${item.color}" class="mr-2 pt-1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-70 0 290 200"  ><path fill="#fff" d="${item.icon.icon[4]}" ></path></svg>${item.name}</div>`
      })
      const joinedTooltip = tooltipElements.join("")
      return `<div class="socialmedia-tooltip d-flex flex-column">${joinedTooltip}</div>`
    },
    x: {
      show: false,
    },
    z: {
      show: false,
    },
    y: {
      formatter: function (val: any, op: any) {
        return `${val} Follower`
      },
      title: {
        formatter: function (val: any, op: any) {
          return extractedList.value?.domains![op.dataPointIndex]
        },
      },
    },
  },
  xaxis: {
    labels: {
      show: false,
    },
    categories: extractedList.value.domains,
  },
  yaxis: {
    // labels: {
    //   renderer: () => {
    //     return ({ value, index }) => {
    //       return `<span>hello</span>`
    //     }
    //   },
    // },
    // labels: {
    //   useHTML: true,
    //   style: {
    //     colors: "red",
    //     fontSize: "16px",
    //     fontFamily: "Font Awesome 5 Brand",
    //     cssClass: "fa:fab fa-facebook",
    //   },
    // },
  },
}
</script>

<template>
  <apexchart
    type="bar"
    height="100%"
    ref="chart"
    :options="chartOptions"
    :series="series"
  >
  </apexchart>
</template>

<style lang="scss">
.socialmedia-tooltip {
  background: white;
  padding: 8px;

  svg {
    border-radius: 50%;
    path {
      transform: scale(0.3);
    }
  }
}
</style>
