<script lang="ts" setup>
import { ImageInfo } from "@/common/store/dossier/types/view"
import CardBlock from "@/common/components/view/CardBlock.vue"
import GalleryBlock from "@/common/components/view/GalleryBlock.vue"
import { mdiClose } from "@mdi/js"
import { vOnClickOutside } from "@vueuse/components"
import type { OnClickOutsideHandler } from "@vueuse/core"
import {
  EntityDataEntityIdentifier,
  EntityDataInfo,
} from "@/common/store/dossier/types"
type GalleryEntry = {
  cols: number
  image: ImageInfo
}
const props = defineProps<{
  gallery?: GalleryEntry[]
  entities?: {
    [id: EntityDataEntityIdentifier]: EntityDataInfo
  }
}>()

const modelValue = defineModel<boolean>()

const drawerHandler: OnClickOutsideHandler = () => {
  if (modelValue.value) modelValue.value = false
}
</script>

<template>
  <v-navigation-drawer
    temporary
    width="650"
    location="right"
    :scrim="false"
    v-model="modelValue"
  >
    <v-btn
      variant="plain"
      :icon="mdiClose"
      color="primary"
      @click="modelValue = false"
      style="position: absolute; top: 0; right: 0; z-index: 2"
      class="mr-2 pa-0"
    />

    <v-sheet
      class="pa-2"
      color="transparent"
      v-on-click-outside.bubble="drawerHandler"
      ><CardBlock
        class="mb-4"
        v-if="gallery?.length"
        title="Fotos"
        col-span="3"
        id="photos"
      >
        <GalleryBlock :list="gallery" :entities="entities" /> </CardBlock
    ></v-sheet>
  </v-navigation-drawer>
</template>
