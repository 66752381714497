import { defineStore } from "pinia"
import { computed, ref } from "vue"

export const useHighlightStore = defineStore("highlight", () => {
  const highlights = ref({} as Record<string, Set<string | null>>)

  function hOn(entity: string, source: string | null = null) {
    ;(highlights.value[entity] ??= new Set()).add(source)
  }

  function hOff(entity: string, source: string | null = null) {
    ;(highlights.value[entity] ??= new Set()).delete(source)
  }

  const highlighted = computed(() => {
    return Object.entries(highlights.value)
      .filter(([entity, sources]) => sources.size > 0)
      .map(([entity, _sources]) => entity)
  })

  return {
    highlighted,
    highlights,

    hOn,
    hOff,
  }
})
