import config from "@/config"
import { ApiUrl } from "@/common/lib/types"
import { trimEnd, trimStart } from "lodash"
import { AtomicOperation } from "@/common/lib/AtomicOperator"

export type ApiReturn = Record<string, any> | string | Uint8Array
export type ApiRegistryOptions = {
  basePath: string
  lookupField: string
}
export type ApiReference = ApiRegistryOptions & {
  lookupValue: string | null
  extra?: string
}

export class ApiFetchError extends Error {
  status: number
  constructor(m: string, status: number) {
    super(m)
    this.status = status

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiFetchError.prototype)
  }
}

export class ApiBase {
  public readonly url: string
  private requestCache = new Map<ApiUrl, Promise<ApiReturn>>()
  public localHeaders: Record<string, string> = {}

  private readonly API_SPECIAL_CASES: ApiRegistryOptions[] = [
    { basePath: "associatedkey", lookupField: "kid" },
    { basePath: "link", lookupField: "slug" },
  ]

  public constructor(baseUrl?: string) {
    this.url = trimEnd(baseUrl || config.backend.api, "/") + "/"
  }

  public normalizeUrl(path: string): ApiUrl {
    return new URL(trimStart(path, "/"), this.url).toString()
  }

  public simplifyUrl(url: ApiUrl): string {
    return this.normalizeUrl(url).slice(this.url.length)
  }

  public deconstructApiUrl(url: ApiUrl): ApiReference {
    const shortUrl = this.simplifyUrl(url)
    const pathParts = shortUrl.split("/")
    if (pathParts.length < 1) {
      throw new Error(`Reference ${url} not fully formed`)
    }
    const objectBase = pathParts.shift()!
    const lookupValue = pathParts.shift() || null
    const extra = pathParts.join("/") || undefined
    for (const typeEntry of this.API_SPECIAL_CASES) {
      if (typeEntry.basePath == objectBase) {
        return { ...typeEntry, lookupValue, extra }
      }
    }
    // Return default construction
    return {
      basePath: objectBase,
      lookupField: "id",
      lookupValue,
      extra,
    }
  }

  public async genericFetch<T extends ApiReturn>(
    method: string,
    path: string,
    body: any,
    headers: Record<string, string> = {},
  ): Promise<T> {
    const targetUrl = this.normalizeUrl(path)
    let contentType = "application/json"
    let raw = false

    if (body instanceof Uint8Array) {
      raw = true
      contentType = "application/octet-stream"
    } else if (body instanceof FormData) {
      raw = true
    }

    const response = await fetch(targetUrl, {
      method: method,
      headers: {
        ...(body !== undefined &&
          !(body instanceof FormData) && { "Content-Type": contentType }),
        ...this.localHeaders,
        ...headers,
      },
      ...(body !== undefined && { body: raw ? body : JSON.stringify(body) }),
    })

    if (!response.ok) {
      throw new ApiFetchError(response.statusText, response.status)
    }

    if (response.headers.get("Content-Type") === "application/json") {
      return await response.json()
    } else if (
      response.headers.get("Content-Type") === "application/octet-stream"
    ) {
      return new Uint8Array(await response.arrayBuffer()) as T
    } else {
      return (await response.text()) as T
    }
  }

  public async GET<T extends ApiReturn>(
    path: string,
    headers: Record<string, string> = {},
  ): Promise<T> {
    /*
     * Uncached GET request. Multiple parallel invocations will return the same promise,
     * but otherwise there's no caching
     */
    const targetUrl = this.normalizeUrl(path)
    let runningRequest = this.requestCache.get(targetUrl)
    if (!runningRequest) {
      runningRequest = (async () => {
        const result = await this.genericFetch<T>(
          "GET",
          path,
          undefined,
          headers,
        )
        this.requestCache.delete(targetUrl)
        return result
      })()
      this.requestCache.set(targetUrl, runningRequest)
    }
    return runningRequest as Promise<T>
  }

  public async POST<T extends ApiReturn>(
    path: string,
    body: any,
    headers: Record<string, string> = {},
  ): Promise<T> {
    return this.genericFetch("POST", path, body, headers)
  }

  public async PATCH<T extends ApiReturn>(
    path: string,
    body: any,
    headers: Record<string, string> = {},
  ): Promise<T> {
    return this.genericFetch("PATCH", path, body, headers)
  }

  public async PUT<T extends ApiReturn>(
    path: string,
    body: any,
    headers: Record<string, string> = {},
  ): Promise<T> {
    return this.genericFetch("PUT", path, body, headers)
  }

  public async DELETE<T extends ApiReturn>(
    path: string,
    headers: Record<string, string> = {},
  ): Promise<T> {
    return this.genericFetch("DELETE", path, undefined, headers)
  }

  public async atomic(
    operations: AtomicOperation[],
  ): Promise<Record<string, any>[]> {
    console.log("Executing atomic operations", JSON.stringify(operations))
    return this.PATCH<Record<string, any>[]>("/atomic/", {
      operations: operations,
    })
  }
}
