import { defineStore } from "pinia"
import { makeObjectStore } from "@/common/store/_helper"
import {
  APIReferenceable,
  ApiUrl,
  EntityPublic,
  ObjectBase,
} from "@/common/lib/types"
import { SecretBoxJson } from "@/common/lib/crypto"
import nacl from "@/common/lib/nacl"
import { AtomicOperator } from "@/common/lib/AtomicOperator"
import { ApiBase } from "@/common/lib/api"
import { b64encode, b64encodeUrlsafe } from "@/common/lib/encoding"
import { User, useUserStore } from "@/common/store/user"
import { useApiStore } from "@/common/store/api"
import { useCryptoStore } from "@/common/store/crypto"
import { useTruthStore } from "@/common/store/truth"

export type WorkstationDTO = ObjectBase & {
  name: string
  user: ApiUrl
}

export type CreatedWorkstation = WorkstationDTO & { password: string }

export const useWorkstationStore = defineStore("workstation", () => {
  const {
    objects: workstations,
    byUrl,
    request: requestWorkstation,
    fetchAll: fetchWorkstations,
  } = makeObjectStore<WorkstationDTO>("workstation/")

  const truthStore = useTruthStore()
  const userStore = useUserStore()
  const crypto = useCryptoStore()
  const api = useApiStore()

  async function createWorkstation(
    user: Partial<User> & APIReferenceable & EntityPublic,
    boxKey: Uint8Array,
    name?: string,
  ): Promise<CreatedWorkstation> {
    // Creation order: a) Create Workstation with empty key data and empty endorsement
    //  just to register the object. It is in an invalid state that cannot be read
    //  b) complete the handshake, receive export_key
    //  c) Update Workstation object for final state
    if (!userStore.currentUser) {
      throw new Error("Not logged in ")
    }
    const password = nacl.random_bytes(16)

    if (!name) {
      name = `Workstation ${user.display_name}`.trim()
    }

    let ws = (
      await new AtomicOperator(api.base as ApiBase)
        .add<WorkstationDTO>("/workstation/", {
          name,
          user,
          private_data: "",
        })
        .do()
    )[0]

    const { op, export_key } = await crypto.register(ws, password)

    const exportBox = new SecretBoxJson(export_key.slice(0, 32))

    op.replace(
      ws,
      "private_data",
      exportBox.encryptJson({ box_key: b64encode(boxKey) }),
    )

    op.output(ws)
    ws = truthStore.update((await op.do()) as any).pop() as WorkstationDTO

    return {
      ...ws,
      password: b64encodeUrlsafe(password),
    } as CreatedWorkstation
  }

  async function deleteWorkstation(workstation: WorkstationDTO) {
    const op = new AtomicOperator(api.base as ApiBase)
    op.remove(workstation)
    await op.do()
    // FIXME HACK HACK HACK
    window.setTimeout(
      () => truthStore.data.set(workstation.url, undefined),
      100,
    )
  }

  return {
    byUrl,
    workstations,
    requestWorkstation,
    fetchWorkstations,

    createWorkstation,
    deleteWorkstation,
  }
})
