import { defineStore, storeToRefs } from "pinia"
import { useApiStore } from "@/common/store/api"
import { useAuthStore } from "@/common/store/auth"
import { computed, reactive, Ref, ref, watchEffect } from "vue"
import {
  B64Data,
  EndorsedObj,
  KeyID,
  ObjectBase,
  SecretAccessPointData,
  SecretEntityData,
} from "@/common/lib/types"
import {
  CryptoError,
  CryptoHelper,
  CryptoManager,
  EntityPrivateBox,
  EntityPublicBox,
  getRootOfTrust,
  SecretBoxJson,
} from "@/common/lib/crypto"
import { b64decode, b64encode } from "@/common/lib/encoding"
import { useTruthStore } from "@/common/store/truth"
import { until } from "@vueuse/core"
import { User, useUserStore } from "@/common/store/user"
import { setsAreEqual } from "@/common/lib/util"
import { LinkDTO } from "@/common/store/dossier"
import { AtomicOperator } from "@/common/lib/AtomicOperator"
import { ApiBase } from "@/common/lib/api"
import { WorkstationDTO } from "@/common/store/workstation"

export type AssociatedKey = ObjectBase &
  EndorsedObj & {
    kid: KeyID
    wrapped_key: B64Data
  }

export type EntityBoxInfo = {
  obj: User | LinkDTO
  box: EntityPublicBox
  entity_id: string
}

export const useCryptoStore = defineStore("crypto", () => {
  const api = useApiStore()
  const auth = useAuthStore()
  const user = useUserStore()
  const truth = useTruthStore()

  const keyRing = reactive(new Map<KeyID, SecretBoxJson>())
  const ready = ref(false)

  const exportKeyBox = ref(null as SecretBoxJson | null)
  const accessPointBox = ref(null as SecretBoxJson | null)
  const entityBox = ref(null as EntityPrivateBox | null)
  const manager = ref(null as CryptoManager | null)

  // region lock/unlock
  watchEffect(() => {
    if (!auth.session || !api.ready) {
      ready.value = false
      lock()
    } else {
      unlock(
        auth.session.exportKey,
        auth.session.accessPoint,
        auth.session.entity,
      )
      ready.value = true
    }
  })

  watchEffect(() => {
    // This *should* by all rights be done in unlock() (with user: Ref<User|null>)
    // But for some reason user.currentUser is null and not reactive then
    if (ready.value && entityBox.value && user.currentUser) {
      manager.value = new CryptoManager(
        entityBox.value as EntityPrivateBox,
        user.currentUser,
        getRootOfTrust(),
      )
    } else {
      manager.value = null
    }
  })

  function unlock(
    exportKey: string,
    accessPoint: SecretAccessPointData,
    entity: SecretEntityData,
  ) {
    if (!accessPoint?.private_data || !entity?.keybox) {
      throw new CryptoError()
    }
    exportKeyBox.value = new SecretBoxJson(b64decode(exportKey).slice(0, 32))

    const apData = exportKeyBox.value.decryptJson<{ box_key: string }>(
      b64decode(accessPoint.private_data),
    )
    accessPointBox.value = new SecretBoxJson(b64decode(apData.box_key))

    entityBox.value = EntityPrivateBox.parsePrivate({
      ...accessPointBox.value.decryptJson<{
        sb_priv: string
        sign_priv: string
      }>(entity.keybox),
      id: entity.id,
    })
  }

  function lock() {
    keyRing.clear()
    if (manager.value) {
      manager.value.clear()
    }
    manager.value = null
    exportKeyBox.value = null
    accessPointBox.value = null
    entityBox.value = null
  }

  // endregion

  function getSecretBox(kid: KeyID): Ref<SecretBoxJson | null> {
    return computed(() => {
      if (!entityBox.value) {
        return null
      }
      const path = `associatedkey/${kid}/`
      if (!fetch && !truth.data.has(path).value) {
        return null
      }
      const ak = truth.data.get<AssociatedKey>(path)
      if (!ak.value) {
        return null
      }
      // Check endorsement
      // await this.verifyEndorsement(ak)
      const key = entityBox.value.unwrap(b64decode(ak.value.wrapped_key))
      if (!key) {
        throw new CryptoError("No key")
      }
      return new SecretBoxJson(key)
    })
  }

  function getEntityBoxInfo(obj: User | LinkDTO): EntityBoxInfo {
    return {
      obj,
      entity_id: obj.id,
      box: EntityPublicBox.parsePublic(obj),
    }
  }

  function getEntityBoxes(...entities: (User | LinkDTO)[]): EntityBoxInfo[] {
    const superusers = user.users.filter((user) => user.type === "superuser")
    if (manager.value!.rot) {
      if (superusers.length != manager.value!.rot.superuser_keys.length) {
        throw new Error("RoT Sanity check: Superuser key mismatch")
      }
      if (
        !setsAreEqual(
          new Set(manager.value!.rot.superuser_keys),
          new Set(superusers.map((su) => su.signature_pubkey)),
        )
      ) {
        throw new Error("RoT Sanity check: Superuser key mismatch")
      }
    }
    const implicitEntities = superusers

    const result: Record<string, EntityBoxInfo> = {}
    for (const obj of [...entities, ...implicitEntities]) {
      if (!(obj.url in result)) {
        result[obj.url] = getEntityBoxInfo(obj)
      }
    }
    return Object.values(result)
  }

  async function fetchSecretBox(kid: KeyID): Promise<Ref<SecretBoxJson>> {
    const path = `associatedkey/${kid}/`
    await until(entityBox).toBeTruthy()
    await truth.fetch(path)
    return getSecretBox(kid) as Ref<SecretBoxJson>
  }

  async function register(
    obj: LinkDTO | WorkstationDTO,
    password: Uint8Array,
  ): Promise<{ op: AtomicOperator<[void]>; export_key: Uint8Array }> {
    const helper = new CryptoHelper()
    try {
      // this.loginState.value = 'securing'
      const ke1 = helper.registrationStep1(password)
      const step1Response: Uint8Array = await api.base.POST(
        `${obj.url}registration_step1/`,
        ke1,
      )
      const step2Results = helper.registrationStep2(step1Response)
      const op = new AtomicOperator(api.base as ApiBase).replace(
        obj,
        "password_file",
        b64encode(step2Results.ke3),
      )
      return { op, export_key: step2Results.exportKey }
    } finally {
      helper.cleanup()
    }
  }

  return {
    exportKeyBox,
    accessPointBox,
    entityBox,
    manager,

    keyRing,
    ready,

    getSecretBox,
    fetchSecretBox,
    getEntityBoxes,
    getEntityBoxInfo,

    register,
  }
})
