<template>
  <apexchart
    ref="sparkchart"
    type="area"
    height="80%"
    :options="localOptions"
    :series="series"
  ></apexchart>
</template>
<script lang="ts" setup>
import { computed } from "vue"

import { useTheme } from "vuetify/lib/framework.mjs"
const theme = useTheme()

const colors = theme.current.value.colors

const props = withDefaults(
  defineProps<{
    modelValue: Record<number, Record<string, number>>
  }>(),
  {},
)

const localOptions = computed(() => ({
  colors: [colors.accent4],
  chart: {
    type: "area",
    sparkline: {
      enabled: false,
    },
    toolbar: {
      autoSelected: "pan",
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  fill: {
    opacity: 0.3,
  },
  xaxis: {
    crosshairs: {
      width: 1,
    },
    categories: years.value,
  },
  yaxis: {
    show: false,
    min: 0,
  },
}))

const years = computed(() => {
  return Object.keys(props.modelValue).sort()
})

const series = computed(() => {
  return [
    {
      name: "Treffer pro Jahr",
      data: years.value.map((year) =>
        Object.values(props.modelValue[parseInt(year)]).reduce(
          (acc, curr) => acc + curr,
          0,
        ),
      ),
    },
  ]
})
</script>
