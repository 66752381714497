import { B64Data, B64UrlsafeData } from "@/common/lib/types"

export function b64decode(s: B64Data): Uint8Array {
  return Uint8Array.from(atob(s), (char) => char.charCodeAt(0))
}

export function b64encode(s: Uint8Array): B64Data {
  return btoa(String.fromCharCode.apply(null, Array.from(s)))
}

export function b64decodeUrlsafe(s: B64UrlsafeData): Uint8Array {
  return b64decode(s.replace(/_/g, "/").replace(/-/g, "+"))
}

export function b64encodeUrlsafe(s: Uint8Array): B64UrlsafeData {
  return b64encode(s).replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/, "")
}

export function toB64JSON(o: any): B64Data {
  return b64encode(Uint8Array.from(new TextEncoder().encode(JSON.stringify(o))))
}

export function fromB64JSON<T = Record<string, any>>(o: B64Data): T {
  return JSON.parse(new TextDecoder().decode(b64decode(o))) as T
}
