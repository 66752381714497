export function generateRandomString(length: number): string {
  const alphanumericChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = ""

  const maxUnbiasedByteValue = 256 - (256 % alphanumericChars.length)

  for (let i = 0; i < length; i++) {
    let randomByte
    do {
      randomByte = window.crypto.getRandomValues(new Uint8Array(1))[0]
    } while (randomByte >= maxUnbiasedByteValue)

    const index = randomByte % alphanumericChars.length
    result += alphanumericChars[index]
  }

  return result
}
