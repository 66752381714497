import { ApiUrl, ObjectBase } from "@/common/lib/types"
import { ApiBase } from "@/common/lib/api"
import { wrapApiError } from "@/common/store/api"

export type AtomicOperation =
  | { op: "add" | "replace" | "test"; path: string; value: any }
  | { op: "move" | "copy"; path: string; from: string }
  | { op: "remove"; path: string }
  | { op: "output"; path: string }
export type TestableObject = {
  url: string
  cas_hash: string
  [key: string]: any
}
type ObjectData = Record<string, any> & { endorsed_by?: TestableObject }
type KeySubSpecifier = string | TestableObject
type KeySpecifier = string | KeySubSpecifier[]

function isKeySpecifier(obj: any): obj is KeySpecifier {
  if (typeof obj === "string") {
    return true
  }
  if (typeof obj === "object" && obj?.length !== undefined) {
    for (const item of obj as KeySubSpecifier[]) {
      // @ts-ignore
      if (typeof item !== "string" && !(item?.id && item?.cas_hash)) {
        return false
      }
    }
    return true
  }
  return true
}

export class AtomicOperator<T extends (ObjectBase | void | undefined)[] = []> {
  // region internal implementation
  #casObjects: TestableObject[] = []
  #operations: AtomicOperation[] = []

  private addCasTests(objects: TestableObject[]) {
    for (const obj of objects) {
      if (this.#casObjects.find((item) => item.url === obj.url) === undefined) {
        this.#casObjects.push(obj)
      }
    }
  }

  private transformData<T extends Record<string, any>>(
    data: T,
  ): [TestableObject[], Record<keyof T, any>] {
    const objects: ObjectBase[] = []
    const result: Record<keyof T, any> = {} as Record<keyof T, any>

    for (const [name, value] of Object.entries(data) as [keyof T, any][]) {
      if (value?.url && value?.cas_hash) {
        objects.push(value)
        result[name] = value.url
      } else {
        result[name] = value
      }
    }

    return [objects, result]
  }

  private expandKeySpecifier(
    keySpecifier: KeySpecifier,
  ): [TestableObject[], string] {
    if (typeof keySpecifier === "string") {
      return [[], keySpecifier]
    } else {
      const objects = []
      const parts = []
      for (const obj of keySpecifier) {
        if (typeof obj === "string") {
          parts.push(obj)
        } else {
          const reference = this.api.deconstructApiUrl(obj.url)
          objects.push(obj)
          parts.push(reference.lookupValue)
        }
      }
      return [objects, parts.join("/")]
    }
  }

  //endregion

  public constructor(private readonly api: ApiBase) {}

  public render(): AtomicOperation[] {
    return [
      ...this.#casObjects.map((item) => {
        const reference = this.api.deconstructApiUrl(item.url)
        const casType = item?.secret_cas_hash ? "secret_cas" : "cas"
        return {
          op: "test",
          path: `/${reference.basePath}/${reference.lookupValue}/${casType}_hash`,
          value: item[`${casType}_hash`] as string,
        } as AtomicOperation
      }),
      ...this.#operations,
    ]
  }

  /* Execute the atomic operation stack. Abstracts away the CAS tests. Will
   * return backend responses, indexed by operation number in original order
   * (e.g. as if CAS tests didn't happen) */
  public async do(): Promise<T> {
    const results = await wrapApiError(this.api.atomic(this.render()))
    return results.slice(this.#casObjects.length) as T
  }

  public test(...objs: TestableObject[]): AtomicOperator<[...T]> {
    this.addCasTests(objs)
    return this
  }

  public add<A extends ObjectBase | undefined = ObjectBase | undefined>(
    path: string,
    objectData: ObjectData,
  ): AtomicOperator<[...T, A]>
  public add<A extends ObjectBase | undefined = ObjectBase | undefined>(
    obj: TestableObject,
    key: KeySpecifier,
    objectData: ObjectData,
  ): AtomicOperator<[...T, A]>
  public add<A extends ObjectBase | undefined = ObjectBase | undefined>(
    p1: string | TestableObject,
    p2: ObjectData | KeySpecifier,
    p3?: ObjectData,
  ): AtomicOperator<[...T, A]> {
    const objectsToTest = []
    let path
    let objectData
    if (typeof p1 === "string" && typeof p2 !== "string" && p3 === undefined) {
      path = p1
      objectData = p2
    } else if (
      typeof p1 !== "string" &&
      isKeySpecifier(p2) &&
      p3 !== undefined
    ) {
      const reference = this.api.deconstructApiUrl(p1.url)
      const [referencedObjects, key] = this.expandKeySpecifier(p2)
      objectsToTest.push(p1, ...referencedObjects)
      path = `/${reference.basePath}/${reference.lookupValue}/${key}`
      objectData = p3
    } else throw new Error(`Invalid parameters ${p1} ${p2} ${p3}`)

    const [referencedObjects, data] = this.transformData(objectData)
    this.addCasTests([...objectsToTest, ...referencedObjects])
    this.#operations.push({
      op: "add",
      path: path,
      value: data,
    })

    return this as any
  }

  public replace(
    type: string,
    object: TestableObject,
    key: string,
    value: any,
  ): AtomicOperator<[...T, void]>
  public replace(
    object: ObjectBase,
    key: string,
    value: any,
  ): AtomicOperator<[...T, void]>

  public replace(
    p1: string | ObjectBase,
    p2: TestableObject | string,
    p3: string | any,
    p4?: any,
  ): AtomicOperator<[...T, void]> {
    let obj
    let path
    let value
    if (typeof p1 === "string" && typeof p2 !== "string") {
      obj = p2
      path = `/${p1}/${p2.id}/${p3}`
      value = p4
    } else if (typeof p1 !== "string" && p1?.id && p4 === undefined) {
      const reference = this.api.deconstructApiUrl(p1.url)
      obj = p1
      path = `/${reference.basePath}/${reference.lookupValue}/${p2}`
      value = p3
    } else throw new Error(`Invalid parameters ${p1} ${p2} ${p3} ${p4}`)

    const [referencedObjects, data] = this.transformData({ value })
    this.addCasTests([...referencedObjects, obj])
    this.#operations.push({
      op: "replace",
      path: path,
      ...data,
    })

    return this as any
  }

  public remove(
    obj: TestableObject,
    key?: KeySpecifier,
  ): AtomicOperator<[...T, void]> {
    const reference = this.api.deconstructApiUrl(obj.url)
    let additionalObjects: TestableObject[] = []
    let path = ""
    if (key !== undefined) {
      if (isKeySpecifier(key)) {
        let keyPath
        ;[additionalObjects, keyPath] = this.expandKeySpecifier(key)
        path = `/${keyPath}`
      } else {
        throw new Error(`Invalid parameters ${obj} ${key}`)
      }
    }
    this.addCasTests([...additionalObjects, obj])
    this.#operations.push({
      op: "remove",
      path: `/${reference.basePath}/${reference.lookupValue}${path}`,
    })
    return this as any
  }

  public output<A extends ObjectBase | undefined = ObjectBase | undefined>(
    obj: TestableObject,
  ): AtomicOperator<[...T, A]> {
    const reference = this.api.deconstructApiUrl(obj.url)
    this.addCasTests([obj])
    this.#operations.push({
      op: "output",
      path: `/${reference.basePath}/${reference.lookupValue}`,
    })
    return this as any
  }

  public extend<U extends (ObjectBase | void | undefined)[]>(
    other: AtomicOperator<U>,
  ): AtomicOperator<[...T, ...U]> {
    this.#operations.push(...other.#operations)
    this.addCasTests([...other.#casObjects])
    return this as any
  }

  public rawOperation(op: AtomicOperation): AtomicOperator<[...T, void]> {
    this.#operations.push(op)
    return this as any
  }
}
