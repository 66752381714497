import type { User } from "@/common/store/user"
import { concatenateParts } from "@/common/lib/util"
import { LinkDTO } from "@/common/store/dossier"

export const ENDORSEMENT = {
  AssociatedKey: (data: {
    endorsed_by: User
    kid: string
    entity: LinkDTO | User
    wrapped_key: string
  }) =>
    concatenateParts([
      "v1;AK",
      data.endorsed_by.id,
      data.kid,
      data.entity.id,
      data.wrapped_key,
    ]),
  Dossier: (data: {
    endorsed_by: User
    id: string
    encryption_kid: string
    manifest: string
  }) =>
    concatenateParts([
      "v1;D",
      data.endorsed_by.id,
      data.id,
      data.encryption_kid,
      data.manifest,
    ]),
  Entity: (data: { endorsed_by: User; id: string; signature_pubkey: string }) =>
    concatenateParts([
      "v1;E",
      data.endorsed_by.id,
      data.id,
      data.signature_pubkey,
    ]),
}
