<template>
  <graph-display isSocial :nodes="nodes" :edges="edges" />
</template>

<script lang="ts" setup>
import { IconType, SocialMediaTypeX } from "@/common/store/dossier/types"
import "v-network-graph/lib/style.css"
import { computed, watchEffect } from "vue"
import { useGraph } from "@/common/lib/graph"
import { groupBy } from "lodash"
import GraphDisplay from "@/common/components/view/GraphDisplay.vue"

const props = withDefaults(
  defineProps<{
    items: any
    childrenIcon?: boolean
  }>(),
  {},
)

const { nodes, edges, addEdge, addNode } = useGraph<
  `_site/${string}` | `_group/${string}`,
  | ({
      nodeId: `_site/${string}`
      isParent: false
    } & SocialMediaTypeX)
  | {
      nodeId: `_group/${string}`
      name: string
      color: string
      icon: string | IconType
      isParent: true
    },
  {}
>()

const socialNodes = computed(() => {
  const retval = [] as (SocialMediaTypeX & { nodeId: `_site/${string}` })[]
  for (const [i, social] of props.items.entries()) {
    retval.push({
      ...social,
      nodeId: `_site/${i}`,
    })
  }
  return retval
})
const socialGroups = computed(() => {
  return groupBy(socialNodes.value, "groupName")
})

watchEffect(() => {
  let firstGroupName = null as string | null
  let lastGroupName = null as string | null
  for (const [groupName, entries] of Object.entries(socialGroups.value).sort(
    (a, b) => b[1].length - a[1].length,
  )) {
    addNode(`_group/${groupName}`, {
      name: groupName,
      color: entries[0].color,
      icon: entries[0].icon,
      isParent: true,
    })
    if (!firstGroupName) {
      firstGroupName = groupName
    }
    if (lastGroupName) {
      // addEdge(`_group/${lastGroupName}`, `_group/${groupName}`)
    }
    lastGroupName = groupName

    for (const entry of entries) {
      addNode(entry.nodeId, entry)
      addEdge(`_group/${groupName}`, entry.nodeId)
    }
  }
  if (lastGroupName && firstGroupName && lastGroupName != firstGroupName) {
    // addEdge(`_group/${lastGroupName}`, `_group/${firstGroupName}`)
  }
})
</script>

<style lang="scss"></style>
