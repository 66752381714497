<template>
  <v-card :elevation="1" class="preview-card-block d-flex flex-column">
    <template #title v-if="title || hasTitleSlot">
      <h2 class="card-title text-uppercase text-gray">
        <div class="d-inline-block">
          <slot name="title"> {{ title }} </slot>
          <v-tooltip v-if="tooltip" activator="parent">{{ tooltip }}</v-tooltip>
        </div>
      </h2>
    </template>
    <div
      class="card-body"
      :class="{ 'd-flex align-center flex-column ': center }"
    >
      <slot></slot>
    </div>
  </v-card>
</template>
<script lang="ts" setup>
import { useSlots } from "vue"
const slots = useSlots()
const hasTitleSlot = slots.title !== undefined

interface Props {
  center?: boolean
  title?: string
  tooltip?: string
}
withDefaults(defineProps<Props>(), {
  center: false,
})
</script>
<style lang="scss" scoped>
.preview-card-block {
  overflow: hidden !important;
  .card-title {
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .card-body {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    max-height: 100% !important;
    flex: 1;
  }
}
</style>
