<template>
  <div class="map-wrap">
    <div class="map" ref="mapContainer"></div>
    <div style="position: absolute; right: 0; top: 0; z-index: 2">
      <v-btn-group
        color="primary"
        class="ma-2"
        variant="outlined"
        density="comfortable"
        rounded
      >
        <v-btn :icon="mdiMagnifyMinusOutline" @click="map.zoomOut()" />
        <v-btn :icon="mdiMagnifyPlusOutline" @click="map.zoomIn()" />
      </v-btn-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiMagnifyMinusOutline, mdiMagnifyPlusOutline } from "@mdi/js"
import {
  shallowRef,
  onMounted,
  onUnmounted,
  defineProps,
  Ref,
  ref,
  computed,
  watch,
} from "vue"
import { watchArray } from "@vueuse/core"
import * as maplibregl from "maplibre-gl"
import "maplibre-gl/dist/maplibre-gl.css"
import {
  EntityDataEntityIdentifier,
  LocationInfo,
} from "@/common/store/dossier"
import { styles } from "@versatiles/style"
import { useHighlightStore } from "@/common/store/view/highlight"
import { storeToRefs } from "pinia"

const style = styles.graybeard({
  baseUrl: "https://tiles.lagebild.digital",
  tiles: ["https://tiles.lagebild.digital/tiles/osm/{z}/{x}/{y}"],
})

const mapContainer = shallowRef(null) as Ref<HTMLDivElement | null>
const map = shallowRef(null) as any
const { highlighted } = storeToRefs(useHighlightStore())

const props = defineProps<{ modelValue: LocationInfo[] }>()

const currentlyVisible = [] as any[]

const markers = computed(() => {
  const markerElements = [] as any[]
  const retval = {} as Record<EntityDataEntityIdentifier | "", [any, any][]>
  try {
    for (const entry of props.modelValue) {
      const content = entry.title
        ? `${entry.title}<br>${entry.label}<br>${entry.address}`
        : `${entry.address || ""}`
      const p = new maplibregl.Popup({
        offset: 25,
        closeButton: false,
        closeOnClick: false,
      })
        .setLngLat(entry.pos)
        .setHTML(`<p>${content}</p>`)
      const m = new maplibregl.Marker({
        color: entry?.color ?? "#283583",
      })
        .setLngLat(entry.pos)
        .setPopup(p)

      markerElements.push(m)

      for (const entityId of entry.entities) {
        ;(retval[entityId] ??= []).push([m, p])
      }
      ;(retval[""] ??= []).push([m, p])
    }
  } catch (e) {
    console.log("HELP", e)
    throw e
  }

  return { retval, markerElements }
})

watch(
  () => {
    return [map.value, markers.value, highlighted.value]
  },
  ([map, markers, highlighted]) => {
    if (!map || !markers) {
      return
    }
    for (const cv of currentlyVisible) {
      cv.remove()
    }
    currentlyVisible.splice(0, currentlyVisible.length)
    if (!highlighted.length) {
      for (const [m, p] of Object.values(markers.retval[""] || []) as any) {
        map.on("mouseover", (e: any) => {
          let hoveredMarker: any = null
          const overMarker = markers.markerElements.some((el: any) => {
            const rect = el.getElement().getBoundingClientRect()
            const overMarker =
              e.originalEvent.clientX >= rect.left &&
              e.originalEvent.clientX <= rect.right &&
              e.originalEvent.clientY >= rect.top &&
              e.originalEvent.clientY <= rect.bottom
            if (overMarker) hoveredMarker = el
            return overMarker
          })
          if (overMarker && hoveredMarker) {
            m.getPopup().remove()
            hoveredMarker.getPopup().addTo(map)
          } else {
            m.getPopup().remove()
          }
        })

        m.addTo(map)
        currentlyVisible.push(m, p)
      }
    } else {
      for (const h of highlighted) {
        for (const [m, p] of Object.values(markers.retval[h] || []) as any) {
          m.addTo(map)
          m.getPopup().addTo(map)
          currentlyVisible.push(m, p)
        }
      }
    }
  },
)

onMounted(() => {
  const atc = new maplibregl.AttributionControl({
    compact: true,
  })
  map.value = new maplibregl.Map({
    container: mapContainer.value!,
    zoom: 5,
    center: [11, 51],
    scrollZoom: false,
    style,
    attributionControl: false,
  })
    .addControl(atc)
    .on("load", () => atc._toggleAttribution())

  watchArray(
    props.modelValue,
    (newList, oldList, added, removed) => {
      if (newList.length) {
        const region = new maplibregl.LngLatBounds(
          newList[0].pos,
          newList[0].pos,
        )
        for (const point of newList) {
          region.extend(point.pos)
        }
        map.value.fitBounds(region, { padding: 30, maxZoom: 7 })
      }
    },
    { immediate: true },
  )
})

onUnmounted(() => {
  map.value?.remove()
})
</script>

<style>
.map-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
