<template>
  <v-main>
    <div
      style="
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        z-index: 2;
      "
      v-if="loginState"
    >
      <v-card
        :style="{ 'max-width': showPasskeyIcon ? '50em' : '30em' }"
        outlined
        color="rgba(255,255,255,0.8)"
      >
        <template v-if="loginState == 'activatePin' && pinState">
          <v-card-title class="bg-primary">{{
            t("pinProcess." + pinState + ".title")
          }}</v-card-title>
          <v-card-text class="pt-2" style="white-space: pre-line">{{
            t("pinProcess." + pinState + ".text")
          }}</v-card-text>
          <template v-if="pinState == 'primed'">
            <v-card-actions
              ><v-spacer /><v-btn variant="flat" @click="reload">{{
                t("action.reload")
              }}</v-btn
              ><v-btn variant="flat" @click="pinState = 'requested'">{{
                t("pinProcess.setPin")
              }}</v-btn></v-card-actions
            >
          </template>
          <template v-else-if="pinState == 'requested'">
            <template v-if="!pin || !pin.length">
              <v-card-text>Hole PIN</v-card-text>
            </template>
            <template v-else>
              <v-card-text class="pb-0 justify-center d-flex align-center">
                <span class="text-h4 mr-3">PIN:</span>
                <span class="bold text-mono text-h4 bg-grey-lighten-3 pa-2">{{
                  pinFormatted
                }}</span>
                <v-btn variant="tonal" class="ml-2" @click="copyPin">{{
                  t("pinProcess.copyPin")
                }}</v-btn>
              </v-card-text>
              <v-snackbar
                v-model="haveCopied"
                :timeout="2000"
                position="relative"
                color="primary"
                >PIN in Zwischenablage kopiert</v-snackbar
              >
              <v-card-actions>
                <v-spacer />
                <v-btn variant="flat" @click="pinState = 'confirmed'">{{
                  t("pinProcess.haveSaved")
                }}</v-btn>
              </v-card-actions>
            </template>
          </template>
        </template>
        <template v-else-if="loginState == 'requirePin'">
          <v-card-title class="bg-primary">{{
            t("pinProcess.required.title")
          }}</v-card-title>
          <v-card-text class="pt-2" style="white-space: pre-line">{{
            t("pinProcess.required.text")
          }}</v-card-text>
          <v-card-text class="pb-0 justify-center d-flex">
            <v-text-field
              v-model="pinInput"
              ref="pinInputField"
              autofocus
              :placeholder="t('pinProcess.inputPin')"
              variant="solo"
              style="max-width: 9em"
              @keydown.enter.prevent="sendPin"
              validate-on="lazy blur"
              :rules="[
                cleanedUpPinInput.length == 6 || t('pinProcess.pinHint'),
              ]"
              id="pin"
              inputmode="numeric"
              autocomplete="current-password"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              variant="flat"
              :disabled="!pinInputPlausible"
              @click="sendPin"
              >{{ t("pinProcess.continue") }}</v-btn
            >
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-title class="bg-primary text-left">{{
            !isError
              ? t("message.loggingIn")
              : t("message.loginProcess." + loginState)
          }}</v-card-title>
          <v-card-text v-if="!isError">{{
            t("message.loginProcess." + loginState)
          }}</v-card-text>
          <v-card-text v-else class="pt-2">
            <p v-if="loginState && loginState.endsWith('Error')">
              {{ t("errorHelp." + loginState) }}
            </p>
            <v-card-actions
              class="mt-2"
              v-if="
                [
                  'otherError',
                  'rateLimitError',
                  'failedError',
                  'webauthnError',
                  'wrongPinError',
                ].includes(loginState)
              "
            >
              <v-spacer /><v-btn variant="flat" @click="reload">{{
                ["wrongPinError"].includes(loginState)
                  ? t("action.pinEntry")
                  : ["webauthnError", "rateLimitError", "otherError"].includes(
                      loginState,
                    )
                  ? t("action.retry")
                  : t("action.reload")
              }}</v-btn>
            </v-card-actions>
          </v-card-text>
          <v-card-text v-if="showPasskeyIcon">
            <v-container>
              <v-row>
                <v-col class="d-flex justify-center align-center" cols="3"
                  ><v-icon :icon="passkeyLogo" size="100"
                /></v-col>
                <v-col cols="9">
                  <template
                    v-if="loginState && loginState.startsWith('registering')"
                  >
                    <p>
                      {{ $t("help.webauthn.registration.firstTime") }}
                    </p>
                    <p>
                      {{ $t("help.webauthn.registration.localBiometry") }}
                    </p>
                    <p>
                      {{ $t("help.webauthn.registration.linkLocked") }}
                    </p>
                  </template>
                  <template
                    v-if="loginState && loginState.startsWith('authenticating')"
                  >
                    <p>
                      {{ $t("help.webauthn.authentication.localBiometry") }}
                    </p>
                  </template>
                  <v-expand-transition
                    ><v-alert
                      v-if="loginState && loginState.endsWith('FocusWait')"
                      border="start"
                      border-color="primary"
                      :icon="mdiCursorDefaultClickOutline"
                      elevation="2"
                      >{{ $t("help.webauthn.clickHereForFocus") }}</v-alert
                    ></v-expand-transition
                  >
                  <p style="font-size: 80%; opacity: 0.8; margin-top: 1em">
                    {{ $t("help.webauthn.passkeyNote") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </div>
    <fullscreen-loader v-if="isRunning" />
  </v-main>
</template>

<script setup lang="ts">
import FullscreenLoader from "./FullscreenLoader.vue"
import { useI18n } from "vue-i18n"
import { computed, ref, watch } from "vue"
import { passkeyLogo } from "@/common/lib/icons"
import { storeToRefs } from "pinia"
import { useAuthStore } from "@/common/store/auth"
import { mdiCursorDefaultClickOutline } from "@mdi/js"

const { t } = useI18n()
const { state: loginState, pinState, pin } = storeToRefs(useAuthStore())

const pinInput = ref("")

const isError = computed(
  () => loginState.value && loginState.value.endsWith("Error"),
)
const activatePin = computed(() => loginState.value == "activatePin")
const requirePin = computed(() => loginState.value == "requirePin")
const isRunning = computed(() => {
  return (
    (loginState.value &&
      !["completed", "unauthenticated", "activatePin", "requirePin"].includes(
        loginState.value,
      ) &&
      !isError.value) ||
    pinState.value == "confirmed"
  )
})
const showPasskeyIcon = computed(
  () =>
    loginState.value &&
    [
      "registeringFocusWait",
      "authenticatingFocusWait",
      "registering",
      "authenticating",
    ].includes(loginState.value),
)

const cleanedUpPinInput = computed(() =>
  pinInput.value ? pinInput.value.split(/[^0-9]/).join("") : "",
)

const pinInputPlausible = computed(() => cleanedUpPinInput.value.length == 6)
const pinFormatted = computed(() => {
  if (pin.value && pin.value.length > 0) {
    const halfLength = pin.value.length / 2
    return `${pin.value.slice(0, halfLength)}-${pin.value.slice(halfLength)}`
  }
  return ""
})

const pinInputField = ref()
watch(pinInputPlausible, (newValue) => {
  if (newValue && pinInputField.value) {
    pinInputField.value.resetValidation()
  }
})

async function sendPin() {
  if (!pinInputPlausible.value) {
    return
  }
  pin.value = cleanedUpPinInput.value
  pinState.value = "entered"
}

function reload() {
  window.location.reload()
}

const haveCopied = ref(false)
async function copyPin() {
  if (pin.value) {
    await navigator.clipboard.writeText(pin.value)
    haveCopied.value = true
  }
}
</script>
