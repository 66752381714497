import {
  ChapterRole,
  ChapterType,
  DossierContents,
  HasRole,
  Section,
} from "./types"
import { NonUndefined } from "@/common/lib/types"

export function* iterateDossier<
  R extends Section<ChapterType> = Section<ChapterType>,
>(
  contents: DossierContents,
  includedRoles?: ChapterRole[],
): Generator<R & HasRole & { body: NonUndefined<R["body"]> }, void, undefined> {
  for (const row of contents.rows) {
    for (const item of row) {
      const section = classifySection(item.content) as R & HasRole
      if (!includedRoles || includedRoles.includes(section.meta.role)) {
        yield section as R & HasRole & { body: NonUndefined<R["body"]> }
      }
    }
  }
}

const SECTION_HEADLINES: Partial<Record<ChapterRole, RegExp>> = {
  InputData: /^eingabe(?:daten)?/i,
  Tasks: /^aufgaben?/i,
  AboutPerson: /^zur\s+person|personendaten/i,
  Communication: /^kommunikation/i,
  Report: /^bericht/i,
  MachineLearningTools: /^machine(?:\s|-)+learning(?:\s|-)+tools/i,
  CompanyDatabases: /^unternehmensdatenbank(?:en)?/i,
  DarkWeb: /^dark(?:\s|-)+web/i,
}

function classifySectionByHeadline(
  headline: string,
): ChapterRole & keyof typeof SECTION_HEADLINES {
  for (const [role, regex] of Object.entries(SECTION_HEADLINES)) {
    if (regex.test(headline.trim())) {
      return role as keyof typeof SECTION_HEADLINES
    }
  }

  // Fallback. Should be removed at some point
  return "aux"
}

export function classifySection(
  section: Section<ChapterType>,
): Section<ChapterType> & HasRole {
  let role: ChapterRole | null = null
  let slot: string | undefined = section?.meta?.slot

  if (section.meta?.role) {
    role = section.meta.role
  } else {
    switch (section.type) {
      // Role matches type
      case "SocialMedia":
      case "Gallery":
      case "Chronology":
      case "FeaturedPhoto":
      case "CompanyInformation":
      case "NetworkAnalysis":
      case "Archive":
      case "ExternalWidgets":
        role = section.type
        break

      case "FileCollection":
        role = "Documents"
        break

      case "SavedWebsite":
        if (section.meta.headline.toLowerCase().includes("press")) {
          role = "PressClippings"
        } else {
          role = "Documents"
        }
        break

      // Role from headline
      case "Text":
      case "RichText":
        role = classifySectionByHeadline(section.meta.headline)
        if (role === "aux") {
          role = "Report"
        }
        break

      // Role from headline
      case "KeyValueTable":
      case "CommentsBlock":
        role = classifySectionByHeadline(section.meta.headline)
        break

      case "KpiBarChart":
        role = "Chart"
        break
      case "EntityData":
        role = "EntityData"
        break
      // Note: No default: This ensures that the TypeScript compiler will warn on the type of `role`
      // if we miss a `section.type` case
    }
  }

  if (role == "aux") {
    slot = "A"
  }

  return {
    ...section,
    meta: {
      ...section.meta,
      role,
      slot,
    },
  }
}
