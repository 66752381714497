import { createI18n } from "vue-i18n"
import de from "@/common/locales/de"
import en from "@/common/locales/en"

const language = localStorage.getItem("selectedLanguage") || "de"
const i18n = createI18n({
  locale: language,
  legacy: false,
  fallbackLocale: "de",
  messages: {
    de,
    en,
    "en-US": en,
  },
  fallbackWarn: false,
  missingWarn: true,
})

export default i18n
