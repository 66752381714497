<template>
  <div class="loader-wrap">
    <div class="loader-circles">
      <div v-for="i in 20" :key="i" class="circle" />
    </div>
  </div>
</template>

<style lang="scss">
.loader-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #fff;
  overflow: hidden;
}

.loader-circles {
  $loader-size: 30px;
  $loader-color: #ffffff;
  $loader-speed: 5;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);
  width: $loader-size * 4;
  height: $loader-size * 4;

  .circle {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: transparent;
    border: calc($loader-size/30) solid $loader-color;
    border-radius: 50%;
    border-bottom-color: transparent;
    border-right-color: transparent;
    text-align: center;

    &:nth-child(even) {
      border-color: rgb(var(--v-theme-primary));
      border-bottom-color: transparent;
      border-right-color: transparent;
    }

    &:nth-child(odd) {
      border-color: rgb(var(--v-theme-primary-darken-1));
      border-bottom-color: transparent;
      border-right-color: transparent;
    }

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        width: calc(($loader-size * $i + $loader-size * 10) / 4);
        height: calc(($loader-size * $i + $loader-size * 10) / 4);
        animation: rotate-circle linear infinite;
        animation-duration: 3s + calc($loader-speed/$i) * 1s;
      }
    }
  }

  @for $i from 0 through 19 {
    @keyframes rotate-circle {
      0% {
        transform: scale(2) rotate(0deg);
      }
      50% {
        transform: scale(1) rotate(180deg);
      }
      100% {
        transform: scale(2) rotate(360deg);
      }
    }
  }
}
</style>
