// Utilities
import { createPinia } from "pinia"
import piniaPersist from "pinia-plugin-persist"
import { PiniaSharedState } from "pinia-shared-state"

const pinia = createPinia()
pinia.use(piniaPersist)
pinia.use(
  PiniaSharedState({
    enable: false,
    type: "native",
  }),
)

export default pinia
