import { defineStore } from "pinia"
import { readonly, ref, watch } from "vue"
import config from "@/config"
import { ApiBase, ApiReturn } from "@/common/lib/api"
import { useAuthStore } from "@/common/store/auth"
import { useI18n } from "vue-i18n"

/*
 * API store abstracts the authorization for the API.
 * Once authStore has an authorization token, it is used for all API requests
 * through `api`
 */
export const useApiStore = defineStore("api", () => {
  const url = readonly(ref(config.backend.api as string))
  const api = new ApiBase()
  const authStore = useAuthStore()

  const ready = ref(false)
  const lastError = ref(null as string | null)

  watch(
    () => !!authStore.session && authStore.state === "completed",
    (value) => {
      api.localHeaders = value
        ? {
            Authorization: `Token ${authStore.session!.token}`,
          }
        : {}
      ready.value = value
    },
    {
      flush: "sync",
      immediate: true,
    },
  )

  async function GET<T extends ApiReturn>(
    path: string,
    headers: Record<string, string> = {},
  ): Promise<T> {
    return wrapApiError(api.GET<T>(path, headers))
  }

  return {
    base: api,
    url,
    ready,
    lastError,

    GET,
  }
})

export async function wrapApiError<T>(inner: Promise<T>): Promise<T> {
  try {
    return await inner
  } catch (e) {
    useApiStore().lastError = (e || "networkError").toString()
    throw e
  }
}
