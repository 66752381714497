<template>
  <div class="preview-page">
    <AppDrawer
      v-if="hasAppDrawer"
      v-model="appDrawer"
      :otherReports="otherReports"
      :dossierPreview="dossierPreview"
      :yearEvents="yearEvents"
      @update:model-value="setOpenDrawer(appDrawer ? 'app' : '')"
    />

    <PhotoDrawer
      v-model="photoDrawer"
      :entities="dossierPreview?.entities"
      :gallery="gallery"
      @update:model-value="setOpenDrawer(appDrawer ? 'photo' : '')"
    />

    <SideDrawer
      :nav-list="hasAppDrawer ? navInfo : navInfo.filter((x) => !x.openDrawer)"
      :featured-photo="featuredPhoto"
      :title="dossierPreview!.meta.title"
      :emails="dossierPreview!.emails"
      :social-media="dossierPreview!.social_media"
      :paragraph="mainReport?.richContent"
      @set-app-drawer="appDrawer = !appDrawer"
      @set-photo-drawer="photoDrawer = !photoDrawer"
      @expand-map="expandedMap = !expandedMap"
      :created-date="dossierPreview!.meta.lastChange!"
    />

    <v-main class="viel-grid ma-6">
      <!-- 1A, 1B Main Report -->
      <CardBlock
        class="bericht-block"
        v-if="mainReport && !expandedMap"
        id="mainReport"
        :title="mainReport.title"
        tooltip="Bewertung durch Profiler"
      >
        <v-card-text class="pt-0"
          ><p
            class="rich-content"
            v-html="mainReport.richContent"
            ref="mainReportText"
          ></p>
          <!-- <v-btn
            v-if="hasAppDrawer"
            style="bottom: 22px; right: 22px"
            variant="outlined"
            class="position-absolute"
            @click.stop="appDrawer = !appDrawer"
            >MEHR BERICHTE</v-btn
          > -->
        </v-card-text>
      </CardBlock>

      <!-- External Widget -->
      <template v-if="false">
        <CardBlock
          v-if="widgets.length"
          class="widget-block"
          title="Google Suchtreffer"
          tooltip="externe Quelle: Gezielte Suchen via Google"
        >
          <ExternalWidgets v-model="widgets" />
        </CardBlock>
        <!-- SparklineChart Area -->
        <CardBlock
          v-else-if="Object.keys(yearEvents).length"
          tooltip="Presse, Bilder, Dokumente, Social-Media et. al."
          class="widget-block"
        >
          <template #title
            >{{ `${eventNumber} TREFFER BIS ZUM` }}
            <short-date :date="dossierPreview!.meta.lastChange!" />
          </template>
          <SparklineChart :model-value="yearEvents" />
        </CardBlock>
      </template>

      <!-- Social Media Accounts Nodes -->
      <CardBlock
        v-if="dossierPreview!.featured_snapshot"
        :title="
          dossierPreview!.featured_snapshot.label ||
          dossierPreview!.featured_snapshot.title
        "
        class="social-media-nodes-block"
        tooltip="besonders hervorzuhebendes, relevantes Suchergebnis"
      >
        <FeaturedSnapshot :item="dossierPreview!.featured_snapshot" />
      </CardBlock>
      <CardBlock
        v-else-if="dossierPreview!.social_media"
        class="social-media-nodes-block"
        title="Soziale Medien"
      >
        <SocialMediaGraph :items="dossierPreview!.social_media" />
      </CardBlock>

      <CardBlock class="person-block" ref="personBlock">
        <EntityList @show-entity="showEntity = $event" :entities="entities" />
      </CardBlock>

      <div class="map-nodes-block d-flex">
        <!-- Entities Nodes -->
        <!-- MAP -->
        <CardBlock
          class="col-6 w-100 mr-2"
          :col-span="expandedMap ? 3 : 1"
          v-if="dossierPreview!.locations"
          style="transition: all 0.3s ease-in-out"
          id="locations"
          title="KARTE"
          tooltip="Postleitzahlen aus dem Suchverlauf"
        >
          <MapView :model-value="dossierPreview!.locations" />
          <!-- <v-btn
          style="top: 22px; left: 22px"
          :variant="!expandedMap ? 'outlined' : 'elevated'"
          class="position-absolute bg-surface"
          @click="expandedMap = !expandedMap"
          :text="!expandedMap ? 'Mehr' : 'Weniger'"
        /> -->
        </CardBlock>
        <CardBlock
          class="col-6 w-100 ml-2"
          title="Netzwerk"
          tooltip="Verbindungen von Unternehmen und Personen"
        >
          <main-graph-display
            :preLayouts="predefinedNetworkLayout"
            :view="dossierPreview!"
            @show-entity="showEntity = $event"
            @update:network-positions-out="networkPositionsOut = $event"
          />
        </CardBlock>
      </div>

      <!-- Timeline -->
      <CardBlock
        color="timeline"
        border="2"
        class="timeline-block"
        id="timeline"
        style="overflow: visible"
        v-if="dossierPreview!.events"
      >
        <TimelineBlock
          :entities="dossierPreview?.entities"
          :events="dossierPreview!.events"
          @toggle-app-drawer="appDrawer = true"
        />
      </CardBlock>

      <!--  News Block -->
      <CardBlock
        style="z-index: 9"
        v-if="pressClippings.length"
        class="news-block"
        id="press_clippings"
        tooltip="Archivierte Webseiten mit Links zur aktuellen URL"
      >
        <NewsBlock
          :entities="dossierPreview?.entities"
          :list="pressClippings"
          @set-app-drawer="appDrawer = $event"
        />
      </CardBlock>

      <!-- Social Media Chart -->
      <CardBlock
        class="social-media-chart-block"
        title="Überblick Followerzahlen"
        tooltip="Summe Follower von Personen, Unternehmen"
        center
        v-if="dossierPreview!.social_media.some((x) => x.followers)"
      >
        <SocialMediaChart v-model="dossierPreview!.social_media" />
      </CardBlock>

      <CardBlock
        class="word-cloud-block"
        v-if="dossierPreview"
        title="Häufigste Worte"
        tooltip="Häufung von Begriffen in Berichten und Labeln"
      >
        <WordCloud :view="dossierPreview" />
      </CardBlock>

      <CardBlock
        class="social-media-graph-block"
        title="Alle Suchergebnisse je Jahr"
        tooltip="Diagramm Suchergebnisse"
        v-if="yearEvents"
      >
        <GraphCategoryYear :model-value="yearEvents" />
      </CardBlock>

      <v-dialog
        max-width="50em"
        width="80vw"
        v-if="showEntityDialog"
        v-model="showEntityDialog"
      >
        <entity-data-view :entity-id="showEntity!" :view="dossierPreview!">
          <template #close>
            <v-btn
              :icon="mdiClose"
              @click="showEntity = null"
              color="primary"
              variant="plain"
              size="small"
            />
          </template>
        </entity-data-view>
      </v-dialog>
    </v-main>

    <!-- <Teleport to="#app-bar-title">
      Preview: {{ $t("dossier.dossier") }} {{ dossierPreview.meta.code_name }}
      {{ $t("dossier.ofDate") }}
      <short-date location="bottom" :date="dossierPreview.meta.created_at!" />
    </Teleport> -->
  </div>
</template>

<script setup lang="ts">
import { Ref, ref, watch, watchEffect } from "vue"
import { DossierView, EntityDataEntityIdentifier } from "@/common/store/dossier"
import { computed } from "vue"
import WordCloud from "@/common/components/view/WordCloud.vue"
import { mdiClose } from "@mdi/js"
import ShortDate from "@/common/components/ShortDate.vue"
import SideDrawer from "@/common/components/view/SideDrawer.vue"
import GraphCategoryYear from "@/common/components/view/GraphCategoryYear.vue"
import AppDrawer from "@/common/components/view/AppDrawer.vue"
import PhotoDrawer from "@/common/components/view/PhotoDrawer.vue"
import CardBlock from "@/common/components/view/CardBlock.vue"
import MapView from "@/common/components/view/MapView.vue"
import SparklineChart from "@/common/components/view/SparklineChart.vue"
import SocialMediaChart from "@/common/components/view/SocialMediaChart.vue"
import NewsBlock from "@/common/components/view/NewsBlock.vue"

import TimelineBlock from "@/common/components/view/TimelineBlock.vue"
import EntityList from "@/common/components/view/EntityList.vue"
import MainGraphDisplay from "@/common/components/view/MainGraphDisplay.vue"
import SocialMediaGraph from "@/common/components/view/SocialMediaGraph.vue"
import EntityDataView from "@/common/components/view/EntityDataView.vue"
import { useLayoutData } from "../useLayoutData"
import ExternalWidgets from "@/common/components/view/ExternalWidgets.vue"

import { useDrawerState } from "@/common/lib/util"
import FeaturedSnapshot from "@/common/components/view/FeaturedSnapshot.vue"
import { useElementSize } from "@vueuse/core"

const { openDrawer, setOpenDrawer, closeDrawer } = useDrawerState()
// const appDrawer: Ref<boolean> = ref(false)

// const photoDrawer: Ref<boolean> = ref(false)
const appDrawer = computed({
  get: () => openDrawer.value === "app",
  set: (value) => (value ? setOpenDrawer("app") : closeDrawer()),
})

const photoDrawer = computed({
  get: () => openDrawer.value === "photo",
  set: (value) => (value ? setOpenDrawer("photo") : closeDrawer()),
})

const props = defineProps<{
  dossierPreview: DossierView | null
  preview?: boolean
}>()
const networkPositionsOut = defineModel<any>("networkPositionsOut")

const {
  featuredPhoto,
  gallery,
  mainReport,
  navInfo,
  otherReports,
  pressClippings,
  yearEvents,
  widgets,
  entities,
  predefinedNetworkLayout,
  eventNumber,
} = useLayoutData(props.dossierPreview, "viel")

const showEntity = ref(null as EntityDataEntityIdentifier | null)

const expandedMap: Ref<boolean> = ref(false)
const showEntityDialog = computed({
  get: () => !!showEntity.value,
  set: (value) => {
    if (!value) showEntity.value = null
  },
})

const hasAppDrawer = computed(() => {
  return otherReports.value.some((x) => x.richContent)
})
</script>
<style lang="scss" scoped>
#mainReport .rich-content {
  max-height: 600px;
}
</style>
<style lang="scss">
.preview-page {
  background-color: rgb(var(--v-theme-background));
}

.preview-drawer {
  background-color: rgb(var(--v-theme-secondary));
}

.viel-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr auto 360px 360px 360px 360px;
  grid-template-areas:
    "bericht bericht smgraph smgraph person"
    "bericht bericht smchart wordcloud person"
    "timeline timeline timeline timeline timeline"
    "news news news smnodes smnodes"
    "news news news smnodes smnodes"
    "mapnodes mapnodes mapnodes mapnodes mapnodes"
    "mapnodes mapnodes mapnodes mapnodes mapnodes"
    "widget widget widget widget widget";
  .bericht-block {
    grid-area: bericht;
  }
  .widget-block {
    grid-area: widget;
  }

  .social-media-nodes-block {
    grid-area: smnodes;
  }
  .social-media-graph-block {
    grid-area: smgraph;
  }

  .social-media-chart-block {
    grid-area: smchart;
  }
  .sparkline-block {
    grid-area: sparkline;
  }
  .word-cloud-block {
    grid-area: wordcloud;
  }

  .person-block {
    grid-area: person;
  }

  .map-block {
    grid-area: map;
  }
  .map-nodes-block {
    grid-area: mapnodes;
  }

  .entities-nodes-block {
    grid-area: nodes;
  }

  .timeline-block {
    grid-area: timeline;
    grid-row: auto !important;
  }

  .news-block {
    grid-area: news;
  }
}

.rich-content {
  a {
    color: rgb(var(--v-theme-primary)) !important;
    &:visited {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
  ul,
  ol {
    list-style-position: inside;
  }
  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
}
</style>
