<script lang="ts" setup>
import {
  EntityDataEntityIdentifier,
  EntityDataInfo,
  EventInfo,
} from "@/common/store/dossier/types"
import { computed } from "vue"
import { mdiAccountGroup } from "@mdi/js"
import TimelinePopup from "./TimelinePopup.vue"

const props = defineProps<{
  events: EventInfo[]
  entities?: {
    [id: EntityDataEntityIdentifier]: EntityDataInfo
  }
}>()

const mappedEvents = computed(() => {
  return props.events.map((event) => {
    if (event.type === "document") return undefined
    if (event.type === "image") return undefined
    if (event.type === "social") return undefined
    if (["post"].includes(event.type || "")) {
      return {
        ...event,
        icon: mdiAccountGroup,
      }
    } else {
      return event
    }
  })
})

const groupedEvents = computed(() => {
  const result: any = {}
  const resultByYear: any = {}
  for (const event of mappedEvents.value) {
    if (event && event.date && event.type) {
      const year = event.date.getFullYear()
      if (!(year in result)) {
        console.log("result", result)
        result[year] = {}
        resultByYear[year] = { items: [], count: 0 }
      }
      if (!(event.type in result[year])) {
        result[year][event.type] = {
          entities: event.entities,
          type: event.type,
          icon: event.icon,
          items: [],
          count: 0,
        }
      }
      result[year][event.type].items.push(event)
      result[year][event.type].count++

      resultByYear[year].items.push(event)
      resultByYear[year].count++
    }
  }
  return {
    result,
    resultByYear,
  }
})
const emit = defineEmits(["toggle-app-drawer"])
const toggleAppDrawer = (type: string) =>
  type === "image" && emit("toggle-app-drawer")
</script>

<template>
  <div style="overflow-x: auto">
    <v-timeline
      direction="horizontal"
      side="end"
      class="horizontal-timeline py-4"
    >
      <template v-if="mappedEvents.length > 12">
        <template v-for="(yearItems, year) in groupedEvents.result" :key="year">
          <v-timeline-item
            v-for="(item, type, index) in yearItems"
            :key="type + index"
            fill-dot
            icon-color="indigo-darken-2"
            dot-color="white"
            :icon="item.icon"
          >
            <template #icon>
              <div class="position-relative">
                <!-- <v-tooltip activator="parent" location="bottom">{{
                  $t(`eventsType.${type}`)
                }}</v-tooltip> -->
                <timeline-popup :item="item" :entities="entities" />
              </div>
            </template>
            <template #opposite
              ><h2
                class="card-title text-uppercase text-gray"
                v-if="index === 0"
              >
                {{ year }}
              </h2></template
            >
          </v-timeline-item>
        </template></template
      >

      <template v-else>
        <template
          v-for="(yearItem, index) in groupedEvents.resultByYear"
          :key="index"
        >
          <v-timeline-item
            v-for="(item, sIndex) in yearItem.items"
            :key="sIndex"
            fill-dot
            icon-color="indigo-darken-2"
            dot-color="white"
            :icon="item.icon"
          >
            <template #icon>
              <div class="position-relative">
                <!-- <v-tooltip activator="parent" location="bottom">{{
                  $t(`eventsType.${item.type}`)
                }}</v-tooltip> -->
                <timeline-popup :item="item" :entities="entities" />
              </div>
            </template>
            <template #opposite v-if="sIndex === 0"
              ><h2 class="card-title text-uppercase text-gray">
                {{ index }}
              </h2></template
            >
          </v-timeline-item>
        </template>
      </template>
    </v-timeline>
  </div>
</template>

<style lang="scss">
.horizontal-timeline {
  color: rgb(var(--v-theme-primary)) !important;
  .card-title {
    font-size: 0.75rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .v-timeline-item__opposite {
    padding-block-end: 6px !important;
  }
  .v-timeline-item__body {
    position: relative;
  }
  .timeline-count {
    position: absolute;
    bottom: -17px;
    right: -12px;
    height: 20px;
    font-family: "Open Sans", sans-serif;
  }
}
.timeline-popup-menu {
  color: rgb(var(--v-theme-primary));
  overflow: hidden;
  background-color: #fff;
  width: fit-content;
  max-width: 500px;
  text-wrap: nowrap;
  list-style: none;
  position: relative;
  border-radius: 8px;
  padding: 4px 8px;
  box-shadow: 0px 0px 8px 0px #0000005e;
  margin: 6px 0;
  z-index: 10;
  table {
    background-color: #fff;
    text-align: left;
  }
}
</style>
