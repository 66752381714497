export type ImageInformation = {
  width: number
  height: number
  thumbnail: string
}

export async function getImageInformationForFile(
  src: File,
  longEdge: number = 32,
): Promise<ImageInformation> {
  let url: string | null = null
  try {
    url = URL.createObjectURL(src)
    return await getImageInformationForUrl(url, longEdge)
  } finally {
    if (url) {
      URL.revokeObjectURL(url)
    }
  }
}

function getImageInformation(
  img: HTMLImageElement,
  longEdge: number = 32,
): ImageInformation {
  const canvas = document.createElement("canvas")
  const maxLength = Math.max(img.width, img.height)
  const scale = longEdge / maxLength

  canvas.width = img.width * scale
  canvas.height = img.height * scale

  canvas.getContext("2d")!.drawImage(img, 0, 0, canvas.width, canvas.height)

  return {
    width: img.width,
    height: img.height,
    thumbnail: canvas.toDataURL("image/webp", 0.5),
  }
}

export async function getImageInformationForUrl(
  srcUrl: string,
  longEdge: number = 32,
): Promise<ImageInformation> {
  const img = await imageFromUrl(srcUrl)
  return getImageInformation(img, longEdge)
}

export async function imageFromUrl(url: string) {
  const img = new Image()
  img.src = url
  await new Promise((resolve, reject) => {
    img.onload = resolve
    img.onerror = reject
  })
  return img
}
