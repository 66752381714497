import { IconType } from "@/common/store/dossier/types"
import {
  faBehance,
  faFacebookF,
  faFlickr,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faPatreon,
  faPinterest,
  faSkype,
  faTiktok,
  faTwitch,
  faWikipediaW,
  faXing,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import {
  extractHandle,
  extractTitle,
  setTitleAndHandle,
  extractTitleNonSocialMedia,
} from "@/common/lib/util"

export type ExtractArguments = {
  source?: string
  content: string
  possibleMatch: string
  domainName: string
}
export type ExtractReturn = {
  followers: string
  established: string
  title: string
}
export type Extractor = (x: ExtractArguments) => ExtractReturn

/* eslint-disable no-useless-escape */
export type DomainType = {
  name: string
  abbreviation: string
  icon: IconType | null | string
  regex: string
  type: "social" | "company_db" | "press" | "other"
  color?: string
  extract?: Extractor
}

export const DOMAIN_LIST: DomainType[] = [
  {
    name: "Taz",
    abbreviation: "TZ",
    icon: "",
    regex: "taz.de$",
    type: "press",
  },
  {
    name: "Frankfurter Allgemeine Zeitung",
    abbreviation: "FAZ",
    icon: "",
    regex: "faz.net$",
    type: "press",
  },
  {
    name: "Fuldaer Zeitung",
    abbreviation: "FZ",
    icon: "",
    regex: "fuldaerzeitung.de$",
    type: "press",
  },
  {
    name: "new business magazin",
    abbreviation: "NBM",
    icon: "",
    regex: "new-business.de$",
    type: "press",
  },
  {
    name: "Osthessen Zeitung",
    abbreviation: "OZ",
    icon: "",
    regex: "osthessen-zeitung.de$",
    type: "press",
  },
  {
    name: "openPR-Portal",
    abbreviation: "OP",
    icon: "",
    regex: "openpr.de$",
    type: "press",
  },
  {
    name: "Manager Magazin",
    abbreviation: "MM",
    icon: "",
    regex: "manager-magazin.de$",
    type: "press",
  },
  {
    name: "Hit Radio FFH",
    abbreviation: "FFH",
    icon: "",
    regex: "ffh.de$",
    type: "press",
  },
  {
    name: "Berliner Woche",
    abbreviation: "BW",
    icon: "",
    regex: "berliner-woche.de$",
    type: "press",
  },
  {
    name: "Web Archive",
    abbreviation: "WA",
    icon: "",
    regex: "web.archive.org$",
    type: "other",
  },
  {
    name: "Westdeutsche Zeitung",
    abbreviation: "WZ",
    icon: "",
    regex: "wz.de$",
    type: "press",
  },
  {
    name: "Bild Zeitung",
    abbreviation: "BD",
    icon: "",
    regex: "bz-berlin.de$",
    type: "press",
  },
  {
    name: "World Socialist Web Site",
    abbreviation: "WS",
    icon: "",
    regex: "wsws.org$",
    type: "press",
  },
  {
    name: "Die Bild",
    abbreviation: "DB",
    icon: "",
    regex: "bild.de$",
    type: "press",
  },
  {
    name: "Berliner Kurier",
    abbreviation: "BK",
    icon: "",
    regex: "berliner-kurier.de$",
    type: "press",
  },
  {
    name: "BZ Berlin",
    abbreviation: "BZ",
    icon: "",
    regex: "berliner-zeitung.de$",
    type: "press",
  },
  {
    name: "Süddeutsche Zeitung",
    abbreviation: "SZ",
    icon: "",
    regex: "sueddeutsche.de$",
    type: "press",
  },
  {
    name: "Die Welt",
    abbreviation: "DW",
    icon: "",
    regex: "welt.de$",
    type: "press",
  },
  {
    name: "Der Tagesspiegel",
    abbreviation: "TS",
    icon: "",
    regex: "tagesspiegel.de$",
    type: "press",
  },
  {
    name: "Checkpoint Tagesspiegel",
    abbreviation: "CT",
    icon: "",
    regex: "checkpoint.tagesspiegel.de$",
    type: "press",
  },
  {
    name: "Zeit Online",
    abbreviation: "ZO",
    icon: "",
    regex: "zeit.de$",
    type: "press",
  },
  {
    name: "Berliner Morgenpost",
    abbreviation: "BM",
    icon: "",
    regex: "morgenpost.de$",
    type: "press",
  },
  {
    name: "Sportschau",
    abbreviation: "SP",
    icon: "",
    regex: "tokio.sportschau.de$",
    type: "press",
  },
  {
    name: "Cylex",
    abbreviation: "CL",
    icon: "",
    regex: "web2.cylex.de$",
    type: "company_db",
  },
  {
    name: "Locate Family",
    abbreviation: "LF",
    icon: "",
    regex: "locatefamily.com$",
    type: "press",
  },
  {
    name: "Neue Westfälische Zeitung",
    abbreviation: "NW",
    icon: "",
    regex: "nw.de$",
    type: "press",
  },
  {
    name: "Karriereführer",
    abbreviation: "KF",
    icon: "",
    regex: "karrierefuehrer.de$",
    type: "press",
  },
  {
    name: "Professional Podcasts",
    abbreviation: "PP",
    icon: "",
    regex: "professional-podcasts.com$",
    type: "press",
  },
  {
    name: "Immobilienmanager",
    abbreviation: "IM",
    icon: "",
    regex: "immobilienmanager.de$",
    type: "press",
  },
  {
    name: "Wirtschaftswoche",
    abbreviation: "WW",
    icon: "",
    regex: "wiwo.de$",
    type: "press",
  },
  {
    name: "Capital",
    abbreviation: "CA",
    icon: "",
    regex: "capital.de$",
    type: "press",
  },
  {
    name: "Eintracht Frankfurt",
    abbreviation: "EF",
    icon: "",
    regex: "sportakrobatik.eintracht.de$",
    type: "other",
  },
  {
    name: "Eintracht Frankfurt",
    abbreviation: "EF",
    icon: "",
    regex: "turnen.eintracht.de$",
    type: "other",
  },
  {
    name: "Frankfurter Rundschau",
    abbreviation: "FR",
    icon: "",
    regex: "fr.de$",
    type: "press",
  },
  {
    name: "Hessischer Sportakrobatik Verband",
    abbreviation: "HS",
    icon: "",
    regex: "hsav.eu$",
    type: "other",
  },
  {
    name: "Westfalen Blatt",
    abbreviation: "WB",
    icon: "",
    regex: "westfalen-blatt.de$",
    type: "press",
  },
  {
    name: "Entrepreneursclub",
    abbreviation: "EC",
    icon: "",
    regex: "entrepreneursclub.eu$",
    type: "press",
  },
  {
    name: "Gildenhaus",
    abbreviation: "GH",
    icon: "",
    regex: "gildenhaus.de$",
    type: "press",
  },
  {
    name: "Deal Magazin",
    abbreviation: "DM",
    icon: "",
    regex: "deal-magazin.com$",
    type: "press",
  },
  {
    name: "Der Beirat",
    abbreviation: "BE",
    icon: "",
    regex: "beirat.eu$",
    type: "press",
  },
  {
    name: "Handelsblatt",
    abbreviation: "HB",
    icon: "",
    regex: "handelsblatt.com$",
    type: "press",
  },
  {
    name: "Spiegel online",
    abbreviation: "SP",
    icon: "",
    regex: "spiegel.de$",
    type: "press",
  },
  {
    name: "Mannheimer Morgen",
    abbreviation: "MM",
    icon: "",
    regex: "mannheimer-morgen.de$",
    type: "press",
  },
  {
    name: "Tradewheel",
    abbreviation: "TW",
    icon: "",
    regex: "tradewheel.com$",
    type: "company_db",
  },
  {
    name: "Firmenbuch",
    abbreviation: "FI",
    icon: "",
    regex: "firmenbuch.at$",
    type: "company_db",
  },
  {
    name: "The Org",
    abbreviation: "TO",
    icon: "",
    regex: "theorg.com$",
    type: "company_db",
  },
  {
    name: "Justiz Online AT",
    abbreviation: "JO",
    icon: "",
    regex: "justizonline.gv.at$",
    type: "company_db",
  },
  {
    name: "Der Standard",
    abbreviation: "DS",
    icon: "",
    regex: "derstandard.de$",
    type: "press",
  },
  {
    name: "Futurezone",
    abbreviation: "FZ",
    icon: "",
    regex: "futurezone.at$",
    type: "press",
  },
  {
    name: "Trendingtopics",
    abbreviation: "TT",
    icon: "",
    regex: "trendingtopics.eu$",
    type: "press",
  },
  {
    name: "Northdata",
    abbreviation: "ND",
    icon: "",
    regex: "northdata.de$",
    type: "company_db",
  },
  {
    name: "CompanyHouse",
    abbreviation: "CH",
    icon: "",
    regex: "companyhouse.de$",
    type: "company_db",
  },
  {
    name: "Creditreform",
    abbreviation: "CF",
    icon: "",
    regex: "firmeneintrag.creditreform.de$",
    type: "company_db",
  },
  {
    name: "TMDB",
    abbreviation: "TM",
    icon: "",
    regex: "tmdb.eu$",
    type: "company_db",
  },
  {
    name: "Implisense",
    abbreviation: "IM",
    icon: "",
    regex: "implisense.com$",
    type: "company_db",
  },
  {
    name: "WebValid",
    abbreviation: "WV",
    icon: "",
    regex: "webvalid.de$",
    type: "company_db",
  },
  {
    name: "Facebook",
    abbreviation: "FB",
    icon: faFacebookF,
    regex: "facebook\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#00bfa5",
    extract: ({ content, possibleMatch }) => {
      const followersUrl = possibleMatch.split("?")[0] + "/followers/"
      const followersMatch = content.match(
        new RegExp(
          `<a[^>]*href="${followersUrl}"[^>]*>(\\d+)\\s*Follower<\\/a>`,
          "s",
        ),
      )

      let followers = ""
      let established = ""
      let title = ""

      if (followersMatch) {
        followers = followersMatch[1]
      }

      const extractedTitle = extractTitle(content, possibleMatch)
      const splittedTitle = possibleMatch.split("?")[0].split("/")
      const handle = splittedTitle[splittedTitle.length - 1]
      const titleAndHandle = setTitleAndHandle(
        handle || null,
        extractedTitle || null,
      )

      if (titleAndHandle) {
        title = titleAndHandle
      }

      return { followers, established, title }
    },
  },
  {
    name: "X / Twitter",
    abbreviation: "TWTR",
    icon: faXTwitter,
    regex: "(x\\.(?:co\\.uk|[^.]{2,})$)|(twitter\\.(?:co\\.uk|[^.]{2,})$)",
    type: "social",
    color: "#a5d6a7",
    extract: ({ source, content, possibleMatch, domainName }) => {
      let followers = ""
      let established = ""
      let title = ""

      if (source) {
        const followersUrl = `${source}/verified_followers`
        const followersMatch = content.match(
          new RegExp(
            `<a[^>]*href="${followersUrl}"[^>]*>.*?<span[^>]*>.*?<span[^>]*>(.*?)</span>`,
            "s",
          ),
        )

        if (followersMatch) {
          followers = followersMatch[1]
        }

        const creationDateMatch = content.match(
          /Seit\s+\w+\s+(\d{4})\s+bei Twitter/,
        )
        if (creationDateMatch) {
          established = creationDateMatch[1]
        }

        const handle = extractHandle(possibleMatch, content)
        const extractedTitle = extractTitle(content, possibleMatch)
        const titleAndHandle = setTitleAndHandle(
          handle || null,
          extractedTitle || null,
        )

        if (titleAndHandle) {
          title = titleAndHandle
        }
      }

      return { followers, established, title }
    },
  },
  {
    name: "Instagram",
    abbreviation: "IG",
    icon: faInstagram,
    regex: "instagram\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#7986CB",
    extract: ({ content, possibleMatch }) => {
      const followersMatch = content.match(
        /<meta\s*property="og:description"\s*content="(\d+)\s+Follower/,
      )
      let followers = ""
      let established = ""
      let title = ""

      if (followersMatch) {
        followers = followersMatch[1]
      }

      const handle = extractHandle(possibleMatch, content)
      const extractedTitle = extractTitle(content, possibleMatch)
      const titleAndHandle = setTitleAndHandle(
        handle || null,
        extractedTitle || null,
      )

      if (titleAndHandle) {
        title = titleAndHandle
      }

      return { followers, established, title }
    },
  },
  {
    name: "TikTok",
    abbreviation: "TT",
    icon: faTiktok,
    regex: "tiktok\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#010101",
  },
  {
    name: "LinkedIn",
    abbreviation: "LN",
    icon: faLinkedinIn,
    regex: "linkedin\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#303f9f",
    extract: ({ content, possibleMatch }) => {
      let followers = ""
      let established = ""
      let title = ""

      const handle = extractHandle(possibleMatch, content)
      const extractedTitle = extractTitle(content, possibleMatch)

      if (extractedTitle) {
        title = extractedTitle
      }

      return { followers, established, title }
    },
  },
  {
    name: "Youtube",
    abbreviation: "YT",
    icon: faYoutube,
    regex: "(youtu\\.be$)|(youtube\\.(?:co\\.uk|[^.]{2,})$)",
    type: "social",
    color: "#ffab40",
    extract: ({ content }) => {
      const subscribersMatch = content.match(
        /<yt-formatted-string[^>]*id="subscriber-count"[^>]*>(.*?)<\/yt-formatted-string>/,
      )
      let followers = ""
      let established = ""
      let title = ""

      if (subscribersMatch) {
        const numericSubscribers = subscribersMatch[1].match(/\d+/g)?.join("")
        followers = numericSubscribers || ""
      }

      const handleMatch = content.match(
        /<yt-formatted-string[^>]*id="channel-handle"[^>]*>(.*?)<\/yt-formatted-string>/,
      )
      if (handleMatch) {
        const handle = handleMatch[1]
        const titleAndHandle = setTitleAndHandle(handle || null, null)
        if (titleAndHandle) {
          title = titleAndHandle
        }
      }

      return { followers, established, title }
    },
  },
  {
    name: "Pinterest",
    abbreviation: "PINT",
    icon: faPinterest,
    regex: "pinterest\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#ff8a80",
  },
  {
    name: "Wikipedia",
    abbreviation: "WP",
    icon: faWikipediaW,
    regex: "wikipedia\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#ffd740",
    extract: ({ content, possibleMatch, domainName }) => {
      const foundingDateMatch = content.match(
        /<th[^>]*>\s*Gründung\s*<\/th>\s*<td[^>]*>\s*(\d{4})\s*<\/td>/,
      )
      let followers = ""
      let established = ""
      let title = ""

      if (foundingDateMatch) {
        established = foundingDateMatch[1]
      }

      const pageTitle = extractTitle(content, possibleMatch)
      const extractedTitle = extractTitleNonSocialMedia(
        pageTitle || "",
        domainName,
      )

      if (extractedTitle) {
        title = extractedTitle
      }

      return { followers, established, title }
    },
  },
  {
    name: "Skype",
    abbreviation: "SKYPE",
    icon: faSkype,
    regex: "skype\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#00aff0",
  },
  {
    name: "Google",
    abbreviation: "GOOGLE",
    icon: faGoogle,
    regex: "google\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#4285f4",
  },
  {
    name: "Twitch",
    abbreviation: "TWITCH",
    icon: faTwitch,
    regex: "twitch\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#9146ff",
  },
  {
    name: "Xing",
    abbreviation: "XING",
    icon: faXing,
    regex: "xing\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#006567",
  },
  {
    name: "Behance",
    abbreviation: "BH",
    icon: faBehance,
    regex: "behance\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#1769ff",
  },
  {
    name: "Patreon",
    abbreviation: "PATRN",
    icon: faPatreon,
    regex: "patreon\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#f96854",
  },
  {
    name: "Flickr",
    abbreviation: "FLKR",
    icon: faFlickr,
    regex: "flickr\\.(?:co\\.uk|[^.]{2,})$",
    type: "social",
    color: "#f40083",
  },
]

export function lookupDomain(value: URL | string) {
  const v = typeof value === "string" ? value : value.hostname
  return DOMAIN_LIST.find((item) =>
    new RegExp(`^(?:[a-zA-Z0-9-]+\\.)*${item.regex}`).test(v),
  )
}

export const getSocialMediaForDomain = (value: string) =>
  DOMAIN_LIST.find(
    (item) =>
      item.type === "social" &&
      new RegExp(`^(?:[a-zA-Z0-9-]+\\.)*${item.regex}`).test(value),
  )
