<template>
  <div style="height: 100%; width: 100%">
    <slot :file="loadedFile"></slot>
  </div>
</template>

<script lang="ts" setup>
import { LabeledFile, useDossierStore } from "@/common/store/dossier"
import { watch } from "vue"

const props = defineProps<{ modelValue: Pick<LabeledFile, "content"> }>()
const dossierStore = useDossierStore()

let loadedFile = await dossierStore.loadRemoteFile(props.modelValue.content)

watch(
  () => props.modelValue,
  async (newModelValue) => {
    loadedFile = await dossierStore.loadRemoteFile(newModelValue.content)
  },
)
</script>
