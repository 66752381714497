/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import { aliases, mdi } from "vuetify/iconsets/mdi-svg"
import { md2 } from "vuetify/blueprints"
import "@/style/main.scss"
import colors from "vuetify/util/colors"

// Fonts
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/700.css"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"

// Localization
import { de, en } from "vuetify/locale"

// Composables
import { ThemeDefinition, createVuetify } from "vuetify"

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { customSVGs } from "@/common/lib/customSvg"
//Custom Theme:
const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#ffffff",
    surface: colors.grey.lighten5,
    timeline: colors.blueGrey.lighten5,
    primary: "#283583",
    secondary: colors.grey.lighten5,
    error: colors.red.darken4,
    info: colors.blue.lighten4,
    success: colors.lightGreen.base,
    warning: colors.red.darken4,
    accent1: colors.teal.accent4,
    accent2: colors.red.accent1,
    accent3: colors.orange.accent2,
    accent4: colors.indigo.lighten1,
    accent5: colors.amber.accent2,
    accent6: colors.green.lighten3,
    accent7: colors.blueGrey.darken2,
    tertiary: colors.indigo.lighten1,
  },
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  blueprint: md2,
  components: { FontAwesomeIcon },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  locale: {
    locale: "de",
    fallback: "en",
    messages: { de, en },
  },
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
      light: {
        colors: {
          surface: "#fcfcfc",
          primary: "#283583",
          "primary-darken-1": "#202A68",
          secondary: colors.red.lighten3,
        },
      },
    },
  },
  defaults: {
    VTooltip: {
      contentClass: "bg-white elevation-3 text-truncate",
    },
  },
})
