import { b64decode, b64encode } from "@/common/lib/encoding"
import { concatenateParts } from "@/common/lib/util"
import nacl from "@/common/lib/nacl"
import { EntityPublic } from "@/common/lib/types"

export class SecretBox {
  protected readonly key: Uint8Array

  constructor(key: Uint8Array) {
    this.key = key
  }

  public encrypt(message: Uint8Array): Uint8Array {
    const nonce = nacl.crypto_secretbox_random_nonce()
    const encryptedBytes = nacl.crypto_secretbox(message, nonce, this.key)
    const encryptedMessage = new Uint8Array(
      nonce.length + encryptedBytes.length,
    )
    encryptedMessage.set(nonce)
    encryptedMessage.set(encryptedBytes, nonce.length)
    return encryptedMessage
  }

  public decrypt(encryptedMessage: Uint8Array): Uint8Array {
    // @ts-ignore
    const NONCE_LENGTH = nacl.crypto_secretbox_NONCEBYTES
    const nonce = encryptedMessage.slice(0, NONCE_LENGTH)
    const encryptedBytes = encryptedMessage.slice(NONCE_LENGTH)
    const decryptedBytes = nacl.crypto_secretbox_open(
      encryptedBytes,
      nonce,
      this.key,
    )
    if (!decryptedBytes) {
      throw new Error("Failed to decrypt message")
    }
    return decryptedBytes
  }

  public wrap(data: Uint8Array): string {
    return b64encode(this.encrypt(data))
  }

  public wrapFor(box: EntityPublicBox): string {
    return b64encode(box.wrap(this.key))
  }
}

type TransformProperties<T, Transformations> = {
  [P in keyof T]: P extends keyof Transformations ? Transformations[P] : T[P]
}

export class SecretBoxJson extends SecretBox {
  constructor(key: Uint8Array) {
    super(key)
  }

  public encryptJson(object: object): string {
    const message = JSON.stringify(object)
    return b64encode(super.encrypt(new TextEncoder().encode(message)))
  }

  public decryptJson<T extends Record<string, any> | string>(
    message: Uint8Array | string,
  ): T {
    const rawMessage =
      typeof message === "string" ? b64decode(message) : message
    const decryptedMessage = super.decrypt(rawMessage)
    return JSON.parse(new TextDecoder().decode(decryptedMessage))
  }

  public decryptStructure<T, Transformations extends Record<string, any>>(
    obj: T,
    ...properties: (keyof Transformations)[]
  ): TransformProperties<T, Transformations> {
    const result: Partial<TransformProperties<T, Transformations>> = {}

    for (const key in obj) {
      if (properties.includes(key as keyof Transformations)) {
        result[key as keyof TransformProperties<T, Transformations>] =
          this.decryptJson((obj as any)[key]) as any
      } else {
        result[key as keyof T] = (obj as any)[key]
      }
    }

    return result as TransformProperties<T, Transformations>
  }
}

export class EntityPublicBox {
  protected readonly entityId: string
  protected readonly signPub: Uint8Array
  protected readonly sbPub: Uint8Array
  protected readonly sbPubSignature: Uint8Array

  static parsePublic({
    id,
    sealedbox_pubkey,
    signature_pubkey,
  }: EntityPublic): EntityPublicBox {
    if (!signature_pubkey.startsWith("s:")) {
      throw new Error("Invalid pubkey format for sign key")
    }

    if (!sealedbox_pubkey.startsWith("e:")) {
      throw new Error("Invalid pubkey format for sealedbox key")
    }
    const parts = sealedbox_pubkey.slice(2).split(",")
    if (parts.length !== 2) {
      throw new Error("Invalid pubkey format for sealedbox key")
    }

    return new EntityPublicBox(
      id,
      b64decode(signature_pubkey.slice(2)),
      b64decode(parts[0]),
      b64decode(parts[1]),
    )
  }

  protected static signatureFormat(
    entityId: string,
    sbPub: Uint8Array,
  ): Uint8Array {
    return concatenateParts(["v1;SB", entityId, "e:" + b64encode(sbPub)])
  }

  constructor(
    entityId: string,
    signPub: Uint8Array,
    sbPub: Uint8Array,
    sbPubSignature: Uint8Array,
  ) {
    this.entityId = entityId
    this.signPub = signPub
    this.sbPub = sbPub
    this.sbPubSignature = sbPubSignature
    if (
      !nacl.crypto_sign_verify_detached(
        sbPubSignature,
        EntityPublicBox.signatureFormat(entityId, sbPub),
        signPub,
      )
    ) {
      throw new Error("Invalid self-signature")
    }
  }

  public wrap(data: Uint8Array): Uint8Array {
    // @ts-ignore  Is missing in @types/js-nacl 1.3.1
    return nacl.crypto_box_seal(data, this.sbPub)
  }

  public verify(data: Uint8Array, signature: Uint8Array) {
    return nacl.crypto_sign_verify_detached(signature, data, this.signPub)
  }

  public dumpPublic(): { sealedbox_pubkey: string; signature_pubkey: string } {
    return {
      sealedbox_pubkey: `e:${b64encode(this.sbPub)},${b64encode(
        this.sbPubSignature,
      )}`,
      signature_pubkey: `s:${b64encode(this.signPub)}`,
    }
  }
}

export class EntityPrivateBox extends EntityPublicBox {
  private readonly sbPriv: Uint8Array
  private readonly signPriv: Uint8Array
  private readonly signSeed: Uint8Array

  static parsePrivate({
    id,
    sb_priv,
    sign_priv,
  }: {
    id: string
    sb_priv: string
    sign_priv: string
  }): EntityPrivateBox {
    return new EntityPrivateBox(id, b64decode(sign_priv), b64decode(sb_priv))
  }

  constructor(id: string, signSeed: Uint8Array, sbPriv: Uint8Array) {
    const signKeypair = nacl.crypto_sign_seed_keypair(signSeed)

    // @ts-ignore  Is missing in @types/js-nacl 1.3.1
    const sbPub = nacl.crypto_scalarmult_base(sbPriv)
    super(
      id,
      signKeypair.signPk,
      sbPub,
      nacl.crypto_sign_detached(
        EntityPrivateBox.signatureFormat(id, sbPub),
        signKeypair.signSk,
      ),
    )

    this.sbPriv = sbPriv
    this.signPriv = signKeypair.signSk
    this.signSeed = signSeed
  }

  public unwrap(data: Uint8Array): Uint8Array | null {
    // @ts-ignore  Is missing in @types/js-nacl 1.3.1
    return nacl.crypto_box_seal_open(data, this.sbPub, this.sbPriv)
  }

  public sign(data: Uint8Array): Uint8Array {
    return nacl.crypto_sign_detached(data, this.signPriv)
  }

  public wrapFor(entityBox: SecretBoxJson): string {
    return entityBox.encryptJson({
      sb_priv: b64encode(this.sbPriv),
      sign_priv: b64encode(this.signSeed),
    })
  }

  static generate(id: string): EntityPrivateBox {
    const sealedbox_keypair = nacl.crypto_box_keypair()
    return new EntityPrivateBox(
      id,
      nacl.random_bytes(32),
      sealedbox_keypair.boxSk,
    )
  }
}
