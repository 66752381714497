<template>
  <v-card>
    <div style="position: absolute; top: 0; right: 0" class="ma-2">
      <slot name="close" />
    </div>
    <person-data-view
      v-if="entity.type === 'person'"
      :entity="entity"
      :view="view"
    />
    <company-data-view
      v-else-if="entity.type === 'company'"
      :entity="entity"
      :view="view"
    />
  </v-card>
</template>
<script lang="ts" setup>
import { DossierView, EntityDataEntityIdentifier } from "@/common/store/dossier"
import { computed } from "vue"
import PersonDataView from "@/common/components/view/PersonDataView.vue"
import CompanyDataView from "@/common/components/view/CompanyDataView.vue"

const props = defineProps<{
  view: DossierView
  entityId: EntityDataEntityIdentifier
}>()

const entity = computed(() => props.view.entities[props.entityId])
</script>
