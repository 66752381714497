<template>
  <v-menu v-if="!url" :open-on-hover="true" open-delay="0">
    <template v-slot:activator="{ props }">
      <span class="event-tooltip-icon-container no-print cursor-pointer">
        <v-icon
          v-bind="props"
          class="text-primary"
          :size="iconSize"
          :icon="mdiInformationOutline"
        />
      </span>
    </template>

    <v-card class="no-print">
      <v-card-text>
        <a
          class="text-primary"
          v-if="url"
          :href="url"
          target="_blank"
          rel="noopener noreferrer"
          v-text="props.source"
        />
        <span v-else v-text="props.source" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts" setup>
import { mdiInformationOutline } from "@mdi/js"
import { computed } from "vue"
import { isValidUrl } from "@/common/lib/util"

const props = defineProps({
  source: { type: String },
  iconSize: { type: String, default: "xs" },
})

const url = computed(() => isValidUrl(props.source!.trim()))
</script>

<style scoped>
.event-tooltip-icon-container {
  display: inline-block;
}
</style>
