<template>
  <v-img
    transition="fade-transition"
    :src="imageUrl"
    :lazy-src="image.thumbnailUrl"
    @click="allowEnlarge ? (overlay = !overlay) : null"
  >
    <slot></slot>
    <v-overlay
      v-if="allowEnlarge && overlay"
      v-model="overlay"
      max-height="90%"
      min-width="60%"
      max-width="85%"
      class="d-flex align-center justify-center"
    >
      <v-card class="mx-auto" max-width="85%">
        <v-toolbar flat>
          <template #title>
            <slot name="title"></slot>
          </template>
          <v-btn @click="overlay = !overlay" :icon="mdiClose" />
        </v-toolbar>
        <v-img
          transition="fade-transition"
          :src="imageUrl"
          :lazy-src="image.thumbnailUrl"
          @click="overlay = !overlay"
        />
      </v-card> </v-overlay
  ></v-img>
</template>
<script lang="ts" setup>
import { ImageInfo } from "@/common/store/dossier"
import { asyncComputed } from "@vueuse/core"
import { Ref, ref } from "vue"
import { mdiClose } from "@mdi/js"

const props = withDefaults(
  defineProps<{
    image: ImageInfo
    allowEnlarge?: boolean
  }>(),
  { allowEnlarge: false },
)

const imageUrl = asyncComputed(async () => {
  return props.image.lazyUrl()
}, undefined)

const overlay: Ref<boolean> = ref(false)
</script>
