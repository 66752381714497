<script lang="ts" setup>
import { computed, onMounted } from "vue"
import { SocialMediaTypeX } from "@/common/store/dossier"
import { groupBy } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

const props = defineProps({
  list: { type: Array as () => SocialMediaTypeX[], default: () => [] },
  elementSpacing: { type: String, default: "ma-2" },
  noWidthOnCount: { type: Boolean, default: false },
  alignment: { type: String, default: "center" },
})
const colorArray = ["blue", "cyan", "green", "pink", "amber", "orange"]

const listGroupedByIcon = computed(() => {
  return groupBy(
    props.list.filter(
      (item) => (!item.type || item.type === "social") && item.icon,
    ),
    (item) => (typeof item.icon === "string" ? item.icon : item.icon.iconName),
  )
})

const getWidthBasedOnCount = computed(() => {
  let width: string | number = 60
  if (props.noWidthOnCount) return "auto"
  const length = Object.keys(listGroupedByIcon.value).length
  if (length <= 4) width = 60
  if (length === 5) width = 50
  if (length >= 6) width = 35
  return `${width}px`
})
</script>

<template>
  <ul
    :class="`social-media-list mt-2 d-flex flex-wrap justify-${alignment} align-${alignment}`"
  >
    <slot></slot>
    <li
      v-for="(content, icon) in listGroupedByIcon"
      :key="icon"
      :class="elementSpacing"
      class="social-media-list-item"
      :style="`width:${getWidthBasedOnCount}`"
    >
      <v-menu :open-on-hover="true" open-delay="0">
        <template #activator="{ props }">
          <v-btn
            target="_blank"
            :href="content.length <= 1 ? content[0].url : ''"
            :color="content[0].color"
            style="color: white !important"
            v-bind="props"
            size="34"
            rounded="xl"
          >
            <font-awesome-icon :icon="content[0].icon" size="xl" />
          </v-btn>
          <v-badge
            v-if="content.length > 1"
            :content="content.length"
            color="accent7"
          >
          </v-badge>
        </template>
        <ul class="social-media-sub-list d-flex flex-column">
          <li
            v-for="(item, index) in content"
            :key="item.url + index"
            class="d-flex my-2 align-center"
          >
            <v-btn
              target="_blank"
              :color="item.color"
              style="color: white !important"
              :href="item.url"
              size="34"
              rounded="xl"
              class="mx-2"
            >
              <font-awesome-icon :icon="item.icon" />
            </v-btn>
            <a
              :href="item.url"
              target="_blank"
              class="text-primary text-decoration-none"
              ><p class="text-subtitle-2 mr-2">{{ item.name }}</p></a
            >
            <v-tooltip max-width="400px" activator="parent">{{
              item.url
            }}</v-tooltip>
          </li>
        </ul>
      </v-menu>
    </li>
  </ul>
</template>
<style lang="scss">
.social-media-list {
  list-style: none;
  width: 80%;
}
.social-media-sub-list {
  min-width: 300px;
  list-style: none;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #0000005e;
}
</style>
