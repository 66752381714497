/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */
// Plugins
import vuetify from "./vuetify"
import pinia from "../store"
import router from "./router"
import VueApexCharts from "vue3-apexcharts"
import i18n from "@/plugins/i18n"
import * as Sentry from "@sentry/vue"

// Types
import type { App } from "vue"
import config from "@/config"

export function registerPlugins(app: App) {
  if (config.sentry.dsn) {
    Sentry.init({
      ...config.sentry,
      app,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      tracePropagationTargets: [config.backend.api, /^\/api\//],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      beforeSend: (event) => {
        if (event.request?.url) {
          const url = new URL(event.request.url)
          if (url.hash) {
            url.hash = "REDACTED" // Removes the hash
            event.request.url = url.toString()
          }
        }
        return event
      },
    })
  }
  app.use(vuetify).use(router).use(pinia).use(i18n).use(VueApexCharts)
}
