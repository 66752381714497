/*
  Image converstion

           if (file.type?.startsWith("image/")) {
            try {
              if (metaMissing(line)) {
                Object.assign(
                  line.content.meta,
                  await getImageInformationForFile(file),
                )
              }
            } catch (e) {
              console.log(
                "Swallowing error during implicit format conversion: " + e,
              )
            }
          }


 */

import {
  ArchiveEntryType,
  ArchiveType,
  ChapterType,
  DateString,
  DossierContents,
  DossierContentSection,
  LabeledFileSectionTypes,
  Section,
  SectionBody,
} from "@/common/store/dossier/types"
import { classifySection } from "@/common/store/dossier/util"
import { removeProxy } from "@/common/lib/util"
import {
  _DEFAULT_ARCHIVE,
  _DEFAULT_EXTERNAL_WIDGETS,
  _DEFAULT_NETWORK_ANALYSIS,
} from "@/common/store/dossier/default"
import short from "short-uuid"

function ensureArchive(contents: DossierContents): {
  archiveContainer: DossierContentSection
  archive: ArchiveType
} {
  // Ensure Archive block exists
  let archiveContainer: DossierContentSection | null = null
  let archive: ArchiveType | null = null
  let berichtPos: [number, number] | null = null

  for (const [rowIdx, row] of contents.rows.entries()) {
    for (const [colIdx, item] of row.entries()) {
      if (item.content.type === "Archive") {
        archiveContainer = item
        break
      }
      const section = classifySection(item.content)
      if (section.meta.role === "Report" && !berichtPos) {
        berichtPos = [rowIdx, colIdx]
      }
    }
    if (archiveContainer) {
      break
    }
  }

  // If we haven't found an existing archive, we'll need to add one
  if (!archiveContainer) {
    if (!berichtPos) {
      // Append at end of first row
      berichtPos = [0, contents.rows[0].length - 1]
    }

    const archiveSection = removeProxy(_DEFAULT_ARCHIVE)
    archiveSection.meta.pos = [berichtPos[0], berichtPos[1] + 1]
    archiveContainer = {
      content: archiveSection,
      sourceBlockId: undefined,
      dirty: true,
    }

    contents.rows[berichtPos[0]].splice(berichtPos[1], 0, archiveContainer)
  }
  archive = (archiveContainer.content as Section<"Archive">).body!

  return { archive, archiveContainer }
}

function migrateFiles<T extends LabeledFileSectionTypes>(
  container: DossierContentSection,
  section: Section<T>,
  type: T,
  archiveContainer: DossierContentSection,
  archive: ArchiveType,
  newType: ArchiveEntryType,
) {
  if (section.type === type) {
    const gallery = section as Section<T>
    let changed = false
    for (const entry of gallery.body!) {
      archive.entries.push({
        title: entry.label,
        source: entry.source,
        type: newType,
        date: entry.date as DateString | undefined,
        files: [entry.content],
      })
      archiveContainer.dirty = true
      changed = true
    }
    if (changed) {
      gallery.body = [] as unknown as SectionBody<T>
      container.dirty = true
    }
  }
}

export function migrateDossierContentsArchiveBlock(
  contents: DossierContents,
): DossierContents {
  const { archiveContainer, archive } = ensureArchive(contents)

  // Find all Photo, press clipping, or document sections and move the data over
  for (const row of contents.rows) {
    for (const container of row) {
      const section = container.content
      if (!section.body) {
        continue
      }
      migrateFiles<"Gallery">(
        container,
        section as Section<"Gallery">,
        "Gallery",
        archiveContainer,
        archive,
        "image",
      )
      migrateFiles<"FileCollection">(
        container,
        section as Section<"FileCollection">,
        "FileCollection",
        archiveContainer,
        archive,
        "document",
      )
      migrateFiles<"SavedWebsite">(
        container,
        section as Section<"SavedWebsite">,
        "SavedWebsite",
        archiveContainer,
        archive,
        "press",
      )
    }
  }

  // If the archive was old and had a `entity`, migrate to `entities`
  for (const entry of archive.entries) {
    if ((entry as any).entity && typeof (entry as any).entity === "string") {
      entry.entities ??= []
      entry.entities.push((entry as any).entity)
      delete (entry as any).entity
      archiveContainer.dirty = true
    }
  }

  return contents
}

export function migrateDossierContentsBaseLayout(
  contents: DossierContents,
): DossierContents {
  // Ensure that network analysis is present
  if (
    !contents.rows.some((row) =>
      row.some((container) => container.content.type === "NetworkAnalysis"),
    )
  ) {
    const networkAnalysisSection = removeProxy(_DEFAULT_NETWORK_ANALYSIS)
    networkAnalysisSection.meta.pos = [9, 9]
    const networkAnalysisContainer: DossierContentSection = {
      content: networkAnalysisSection,
      sourceBlockId: undefined,
      dirty: true,
    }
    contents.rows[contents.rows.length - 1].push(networkAnalysisContainer)
  }

  // Ensure that external widgets block is present
  if (
    !contents.rows.some((row) =>
      row.some((container) => container.content.type === "ExternalWidgets"),
    )
  ) {
    const externalWidgetsSection = removeProxy(_DEFAULT_EXTERNAL_WIDGETS)
    externalWidgetsSection.meta.pos = [9, 9]
    const externalWidgetsContainer: DossierContentSection = {
      content: externalWidgetsSection,
      sourceBlockId: undefined,
      dirty: true,
    }
    contents.rows[contents.rows.length - 1].push(externalWidgetsContainer)
  }

  return contents
}

export function migrateArchiveBlockEnsureID(
  contents: DossierContents,
): DossierContents {
  for (const row of contents.rows) {
    for (const container of row) {
      const section = container.content
      if (!section.body || section.type !== "Archive") {
        continue
      }
      const archive = (section as Section<"Archive">).body!
      for (const entry of archive.entries) {
        if (!entry.id) {
          // Note: don't mark dirty. This is not a visible change, and generating new IDs next time won't hurt
          entry.id = short.generate()
        }
      }
    }
  }
  return contents
}
